import { DATE_DISPLAY_FORMAT, formatDateLocalTime } from 'services/dateService'
import { StyledDatePicker } from './styles'

export type Props = {
  id: string
  label: string
  startSearchParam: string
  endSearchParam: string
  onChange: (params: Dictionary<string | string[] | undefined>) => void
  quickSelectRanges: any
  searchParams: any
}
export const CanvasDatePickerFilter = ({
  label,
  startSearchParam,
  endSearchParam,
  onChange,
  quickSelectRanges,
  searchParams,
}: Props) => {
  const formatDate = (date: Date | undefined) => {
    return date ? formatDateLocalTime(date, DATE_DISPLAY_FORMAT) : undefined
  }

  const onUpdate = (__id: any, value: any) => {
    if (!value) {
      return
    }

    onChange({
      [startSearchParam]: formatDate(value.startDate),
      [endSearchParam]: formatDate(value.endDate),
    })
  }

  return (
    <StyledDatePicker
      key="custom-range-key"
      id="custom-range-id"
      data-testid="rcustom-range-testid"
      type="gregorian-range"
      label={label}
      onUpdate={onUpdate}
      quickSelectRange={quickSelectRanges}
      placeholder="Promo Timeframe"
      value={
        searchParams[startSearchParam]
          ? {
              startDate: searchParams[startSearchParam],
              endDate: searchParams[endSearchParam],
            }
          : undefined
      }
    />
  )
}

export default CanvasDatePickerFilter
