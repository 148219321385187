import { Button, Card, Divider, Grid } from '@enterprise-ui/canvas-ui-react'
import { StyledSubtitle, StyledTitle } from './styles'

interface Props {
  title: string
  subtitle: string
  onUploadFileClick: (drawerType: string) => void
}

export const TwoDayUploadCard = ({
  title,
  subtitle,
  onUploadFileClick,
}: Props) => {
  return (
    <Card className="hc-pa-lg">
      <StyledTitle>{title}</StyledTitle>
      <Divider />
      <Grid.Container justify="space-between">
        <Grid.Item xs={6}>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        </Grid.Item>
        <Grid.Item
          xs={6}
          className="hc-pt-xl"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="secondary"
            className="hc-txt-uppercase"
            onClick={() => onUploadFileClick(title)}
          >
            Upload File
          </Button>
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}

export default TwoDayUploadCard
