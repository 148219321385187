import styled from '@emotion/styled'
import { Grid } from '@enterprise-ui/canvas-ui-react'

export const StyledSubtitle = styled('p')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: theme.palette.grey[700],
  fontSize: 14,
}))

export const StyledTitle = styled('h2')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 18,
  paddingBottom: theme.spacing(2),
}))

export const FileContainer = styled.div`
  border: 1px dashed black;
  border-radius: 8px;
  padding: 24px 10px 24px 10px;
`

export const FileName = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledItem = styled(Grid.Item)({
  position: 'absolute',
  width: '100%',
  bottom: 0,
})
