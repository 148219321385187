import React from 'react'
import startCase from 'lodash/fp/startCase'

import { Link as LinkV2 } from 'components/common/Link'

import LiveLink from 'components/common/LiveLink'
import { OrderTab } from 'components/Orders/OrderDetails'

import {
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  DATE_FORMAT_MONTH_DAY_YEAR,
  DATE_PICKER_FORMAT,
  formatDate,
} from 'services/dateService'
import { getPrettyName } from 'services/enumerations'
import formatCurrency from 'services/formatCurrency'
import { RoutePath } from 'services/NavigationHelper'

import { EnumerationName } from 'types/Enumeration'
import { MarketplaceProduct } from 'types/Item'

interface TcinLink {
  product_id?: string
  seller_id?: string
  tcin?: string
}

interface SkuLink extends TcinLink {
  external_id?: string
}

interface ReturnOrderNumberLink {
  return_order_number?: string
  order_id?: string
  seller_id?: string
}

interface CaseLink {
  seller_id?: string
  case_id?: string
  case_number?: string
}

interface OrderLink {
  seller_id?: string
  id?: string
  order_id?: string
}

export function formatDateMDYT<T>(fieldName: keyof T, nullText: string = '') {
  return (object: T): React.ReactNode => {
    return object[fieldName]
      ? formatDate(object[fieldName] as any, DATE_FORMAT_MONTH_DAY_YEAR_TIME)
      : nullText
  }
}

export function formatDateMDY<T>(fieldName: keyof T, nullText: string = '') {
  return (object: T): React.ReactNode => {
    return object[fieldName]
      ? formatDate(
          object[fieldName] as any,
          DATE_FORMAT_MONTH_DAY_YEAR,
          DATE_PICKER_FORMAT,
        )
      : nullText
  }
}

export function orderStatus<T>(fieldName: keyof T) {
  return (object: T): React.ReactNode => {
    return getPrettyName({
      enumeration: EnumerationName.ORDER_STATUS,
      value: object[fieldName] as any,
    })
  }
}

export function publishedLink<T extends Partial<MarketplaceProduct>>() {
  return ({ buy_url, published }: T) => (
    <LiveLink published={published} buyUrl={buy_url} />
  )
}

export function getPreviouslyApproved<T extends Partial<MarketplaceProduct>>() {
  return ({ previously_approved }: T) => (previously_approved ? 'Yes' : 'No')
}

export function tcinLink<T extends TcinLink>(openInSameTab: boolean = true) {
  let restProps: { rel: string }
  if (!openInSameTab) {
    restProps = { rel: 'noopener noreferrer' }
  }
  return ({ product_id, seller_id, tcin }: T): React.ReactNode => {
    const toPath = `/${seller_id}${RoutePath.ALL_ITEMS}/${product_id}`
    return (
      <LinkV2
        target={openInSameTab ? '_self' : '_blank'}
        to={toPath}
        {...restProps}
      >
        {tcin}
      </LinkV2>
    )
  }
}

export function skuLink<T extends SkuLink>() {
  return ({ product_id, seller_id, external_id }: T) => {
    const toPath = `/${seller_id}${RoutePath.ALL_ITEMS}/${product_id}`
    return <LinkV2 to={toPath}>{external_id}</LinkV2>
  }
}

export function returnOrderNumberLink<T extends ReturnOrderNumberLink>(
  openInSameTab: boolean = true,
) {
  let restProps: { rel: string }
  if (!openInSameTab) {
    restProps = { rel: 'noopener noreferrer' }
  }
  return ({ seller_id, order_id, return_order_number }: T) => {
    const toPath = `/${seller_id}${RoutePath.ORDERS}/${order_id}?tab=${OrderTab.RETURNS}`
    return (
      <LinkV2
        target={openInSameTab ? '_self' : '_blank'}
        to={toPath}
        {...restProps}
      >
        {return_order_number}
      </LinkV2>
    )
  }
}

export function orderLink<T extends OrderLink>() {
  return ({ seller_id, id }: T) => {
    const toPath = `/${seller_id}${RoutePath.ORDERS}/${id}`
    return <LinkV2 to={toPath}>{id}</LinkV2>
  }
}

export function caseLink<T extends CaseLink>() {
  return ({ seller_id, case_id, case_number }: T) =>
    seller_id ? (
      <LinkV2 to={`/${seller_id}${RoutePath.OPEN_CASES}/${case_id}`}>
        {case_number}
      </LinkV2>
    ) : (
      case_number
    )
}

export function prettyCase<T>(fieldName: keyof T, nullText: string = '') {
  return (object: T): React.ReactNode => {
    return object[fieldName]
      ? startCase((object[fieldName] as any).toLowerCase())
      : nullText
  }
}

export function formatAsCurrency<T>(fieldName: keyof T, nullText: string = '') {
  return (object: T): React.ReactNode => {
    return object[fieldName]
      ? formatCurrency(object[fieldName] as any)
      : nullText
  }
}

export function formatPercent<T>(fieldName: keyof T, nullText: string = '') {
  return (object: T): React.ReactNode => {
    return object[fieldName]
      ? `${((object[fieldName] as any) * 100).toFixed()}%`
      : nullText
  }
}

export function formatPercentTwoDecimals<T>(
  fieldName: keyof T,
  nullText: string = '',
) {
  return (object: T): React.ReactNode => {
    return object[fieldName]
      ? `${((object[fieldName] as any) * 100).toFixed(2)}%`
      : nullText
  }
}

export function formatPercentNotFixed<T>(
  fieldName: keyof T,
  nullText: string = '',
) {
  return (object: T): React.ReactNode => {
    return object[fieldName]
      ? `${Math.round((object[fieldName] as any) * 100)}%`
      : nullText
  }
}

export function formatPercentString(val: number) {
  return `${(val * 100).toFixed(2).replace(/\.00$/, '')}%` //rounds decimals to 2 places, no 0's on whole numbers
}
