import startCase from 'lodash/fp/startCase'
import camelCase from 'lodash/fp/camelCase'

import { DialogEnum } from '../common/Dialog'
import TitleBar from '../common/TitleBar'
import DataList, { DataListItem } from '../common/DataList'
import EditButton from '../common/EditButton'

import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_APP_SMS_ADMIN,
} from 'services/roles'
import { approvedToListStatuses } from 'services/seller'

import {
  LABEL_BUSINESS_STRUCTURE,
  LABEL_SOURCE,
  LABEL_COMPANY_TYPE,
} from 'constants/labels'

import { CompanyType, SellerStatus } from 'types/Seller'
import Grid from '@mui/material/Grid'

interface Props {
  memberOf: string[]
  sellerId: string
  stripeId: string | undefined
  taxId: string | undefined
  vmmId: string | undefined
  businessStructure: string | undefined
  sourceLabel: string
  status: SellerStatus
  setupComplete: boolean
  inOtherMarketplaces: boolean | undefined
  isPrivate?: boolean
  canEditStatus: boolean
  plannedSku?: string
}

const getCompanyTypeLabel = (isPrivate?: boolean) => {
  if (isPrivate === true) {
    return startCase(camelCase(CompanyType.PRIVATE))
  } else if (isPrivate === false) {
    return startCase(camelCase(CompanyType.PUBLIC))
  } else {
    return ''
  }
}

export const MetaContent = ({
  memberOf,
  sellerId,
  stripeId,
  taxId,
  vmmId,
  businessStructure,
  sourceLabel,
  status,
  setupComplete,
  inOtherMarketplaces,
  isPrivate,
  canEditStatus,
  plannedSku,
}: Props) => {
  const isProductAdminOrOps = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_OPS,
  ])

  const isExternalAdmin = isOneOfUserRoles(memberOf, [USER_ROLE_APP_SMS_ADMIN])

  const column1: DataListItem[] = [
    {
      title: 'Status',
      value: startCase(status),
      element: (
        <EditButton
          dialogComponent={DialogEnum.EDIT_SELLER_STATUS}
          aria-label="edit partner status"
          hide={!canEditStatus}
        />
      ),
    },
    {
      title: `${LABEL_SOURCE}:`,
      value: sourceLabel,
      element: (
        <EditButton
          dialogComponent={DialogEnum.EDIT_SOURCE}
          aria-label="edit partner source"
          hide={!isProductAdminOrOps}
        />
      ),
    },
    {
      title: 'VMM (Vendor) ID:',
      value: vmmId,
    },
    {
      title: 'SMS ID:',
      value: sellerId,
    },
    {
      title: `${LABEL_COMPANY_TYPE}:`,
      value: getCompanyTypeLabel(isPrivate),
      element: (
        <EditButton
          dialogComponent={DialogEnum.EDIT_COMPANY_TYPE}
          aria-label="edit company type"
          hide={!isExternalAdmin && !isProductAdminOrOps}
        />
      ),
    },
    {
      title: 'Planned SKUs:',
      value: plannedSku ?? 0,
    },
  ]

  const column2: DataListItem[] = [
    {
      title: 'Stripe ID:',
      value: stripeId,
    },
    {
      title: 'Employer Identification Number (EIN):',
      value: taxId,
      element: (
        <EditButton
          dialogComponent={DialogEnum.EDIT_TAX_ID}
          aria-label="edit partner tax id"
          hide={!isProductAdminOrOps}
        />
      ),
    },
    {
      title: 'Internal Tax Setup:',
      value: setupComplete ? 'Completed' : 'Not Completed',
      element: (
        <EditButton
          dialogComponent={DialogEnum.EDIT_INTERNAL_TAX_SETUP}
          aria-label="edit internal tax setup"
          hide={
            (approvedToListStatuses.includes(status) && setupComplete) ||
            !isProductAdminOrOps
          }
        />
      ),
    },
    {
      title: `${LABEL_BUSINESS_STRUCTURE}:`,
      value: businessStructure,
      element: (
        <EditButton
          dialogComponent={DialogEnum.EDIT_BUSINESS_STRUCTURE}
          aria-label="edit partner business structure"
          hide={status !== SellerStatus.PARTNER_SETUP || !isProductAdminOrOps}
        />
      ),
    },
    {
      title: 'Advertises/sells on Other Marketplace:',
      value:
        inOtherMarketplaces === undefined
          ? 'None Provided'
          : inOtherMarketplaces
          ? 'Yes'
          : 'No',
      element: (
        <EditButton
          dialogComponent={DialogEnum.EDIT_OTHER_MARKETPLACES}
          aria-label="edit other marketplaces"
          hide={!isProductAdminOrOps}
        />
      ),
    },
  ]

  return (
    <div>
      <TitleBar title="Business Details" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DataList data={column1} />
        </Grid>
        <Grid item xs={6}>
          <DataList data={column2} />
        </Grid>
      </Grid>
    </div>
  )
}

export default MetaContent
