import { useDispatch } from 'react-redux'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { InfoIcon } from '@enterprise-ui/icons'
import { Tooltip } from '@mui/material'

import { openDialog } from 'store/dialog/actionCreator'
import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatPercentString } from 'components/common/EnhancedTable/formatters'
import { TableHook } from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'

import { memoizedGetSeller } from 'services/seller'
import { calculatePartnerDiscountedMarkup } from 'services/sellerShippingManagement'

import { Carrier } from 'types/Carrier'
import { Discount } from 'types/Markup'
import { DATE_DISPLAY_FORMAT, formatDateLocalTime } from 'services/dateService'
import { StyledEnterpriseIcon } from './styles'

interface Props {
  table: TableHook
  data: Discount[]
  total: number
  isPending: boolean
  handleClose?: (discount: Discount, sellerName: string) => void
}

const fieldList: EnhancedTableFieldType<Discount>[] = [
  {
    key: 'seller_id',
    heading: 'Partner',
    hasSort: true,
    formatCellAsync: async (discount) => {
      const seller = await memoizedGetSeller(discount.seller_id)

      return (
        seller?.display_name ??
        seller?.legal_business_name ??
        discount.seller_id
      )
    },
  },
  {
    key: 'carrier',
    heading: 'Carrier',
    hasSort: true,
    formatCell: (discount) =>
      discount.carrier === Carrier.FEDEX ? 'FedEx' : discount.carrier,
  },
  {
    key: 'rate',
    heading: 'Partner Discount %',
    hasSort: true,
    formatCell: (discount) => {
      if (!discount?.rate) {
        return null
      }

      return (
        <Grid.Container>
          <Grid.Item>{formatPercentString(discount.rate)}</Grid.Item>
          <Grid.Item>
            <Tooltip
              title={`Markup Update: Carrier markup will update on ${formatDateLocalTime(
                discount.start_date,
                DATE_DISPLAY_FORMAT,
              )} at 12am CST`}
            >
              <span>
                <StyledEnterpriseIcon icon={InfoIcon} />
              </span>
            </Tooltip>
          </Grid.Item>
        </Grid.Container>
      )
    },
  },
  {
    key: '',
    heading: 'Discount Markup %',
    formatCellAsync: async (discount) => {
      const discountedMarkup = await calculatePartnerDiscountedMarkup({
        carrier: discount.carrier,
        sellerId: discount.seller_id,
        effectiveDate: discount.start_date,
      })

      return formatPercentString(discountedMarkup)
    },
  },
]

const PartnerDiscountTable = ({
  table,
  data,
  total,
  isPending,
  handleClose,
}: Props) => {
  const dispatch = useDispatch()

  const handleViewDiscountHistory = (sellerId: string, carrier: Carrier) => {
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.VIEW_PARTNER_DISCOUNT_HISTORY,
        componentProps: {
          sellerId,
          carrier,
        },
      }),
    )
  }

  const handleAddPartnerDiscount = async (discount: Discount) => {
    const seller = await memoizedGetSeller(discount.seller_id)
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.ADD_PARTNER_DISCOUNT,
        componentProps: {
          partnerDiscountDetails: discount,
          seller,
          handleClose,
        },
      }),
    )
  }

  const tableActions = [
    {
      label: 'View Partner History',
      callback: (discount: Discount) => () =>
        handleViewDiscountHistory(discount.seller_id, discount.carrier),
    },
    {
      label: 'Edit Partner Markup',
      callback: (discount: Discount) => () =>
        handleAddPartnerDiscount(discount),
    },
  ]

  return (
    <TableSpacer>
      <EnhancedTable
        total={total}
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table.actions.changePerPage}
        onRequestSort={table.actions.sort}
        orderBy={table.state.orderBy}
        order={table.state.direction}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        fieldList={fieldList}
        paginationDisabled={false}
        data={data}
        isLoading={isPending}
        actions={tableActions}
        isUpdatedTable
      />
    </TableSpacer>
  )
}

export default PartnerDiscountTable
