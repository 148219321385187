import { FC } from 'react'

const TopItemType: FC = () => {
  return (
    <div>
      <h1>Top Item Type</h1>
    </div>
  )
}

export default TopItemType
