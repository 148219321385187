import { useState, ChangeEvent, memo } from 'react'
import PriceRangeInput from './PriceRangeInput'

import { PriceRangePayload } from '../types'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PlusCircleIcon, TrashIcon } from '@enterprise-ui/icons'

interface Props {
  priceRangeRows: PriceRangePayload[]
  setError: (error: boolean) => void
  setPriceRangeRows: (priceRange: PriceRangePayload[]) => void
}

export const PriceRangeSection = ({
  priceRangeRows,
  setPriceRangeRows,
  setError,
}: Props) => {
  const [, setMaxPrice] = useState('')
  const [, setReferralPercentage] = useState('')

  const handleOnChangeMaxPrice = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    setMaxPrice(e.target.value)
    const priceRangeRowsCopy = priceRangeRows

    priceRangeRowsCopy[index] = {
      ...priceRangeRowsCopy[index],
      max_price: e.target.value,
    }

    priceRangeRowsCopy[index + 1] = {
      ...priceRangeRowsCopy[index + 1],
      min_price: e.target.value,
    }

    setPriceRangeRows([...priceRangeRowsCopy])
  }

  const handleReferralFeeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    setReferralPercentage(e.target.value)

    const priceRangeRowsCopy = priceRangeRows

    priceRangeRowsCopy[index] = {
      ...priceRangeRowsCopy[index],
      referral_percentage: e.target.value,
    }

    setPriceRangeRows([...priceRangeRowsCopy])
  }

  const handleOnAddRange = () => {
    const priceRangeRowsCopy = priceRangeRows

    priceRangeRowsCopy[priceRangeRows.length - 1] = {
      ...priceRangeRowsCopy[priceRangeRows.length - 1],
      max_price: '',
      referral_percentage: '',
    }

    setPriceRangeRows([
      ...priceRangeRowsCopy,
      {
        min_price: '',
        max_price: '1000000',
        referral_percentage: '',
      },
    ])
  }

  const handleOnDeleteRange = () => {
    const priceRangeRowsCopy = priceRangeRows

    priceRangeRowsCopy.splice(-1)

    priceRangeRowsCopy[priceRangeRowsCopy.length - 1].max_price = '1000000'
    setPriceRangeRows([...priceRangeRowsCopy])
  }

  return (
    <Grid.Container>
      <Grid.Item xs={9}>
        {priceRangeRows.map((rangeItem, index) => (
          <PriceRangeInput
            index={index}
            rangeItem={rangeItem}
            rowLength={priceRangeRows.length - 1}
            handleOnChangeMaxPrice={handleOnChangeMaxPrice}
            handleReferralFeeChange={handleReferralFeeChange}
            setError={setError}
          />
        ))}
      </Grid.Item>
      <Grid.Item
        xs={3}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <Grid.Container
          direction="column"
          align="center"
          spacing="expanded"
          className="hc-pb-md"
        >
          {priceRangeRows.length > 2 && (
            <Grid.Item xs={12} className="hc-pb-md">
              <Button
                key="delete-new-price-range-button"
                data-testid="delete-new-price-range-button"
                type="ghost"
                size="normal"
                onClick={handleOnDeleteRange}
                fullWidth
              >
                <EnterpriseIcon icon={TrashIcon} size="sm" />
                <div className="hc-pl-min">Delete Range</div>
              </Button>
            </Grid.Item>
          )}
          <Grid.Item xs={12}>
            <Button
              fullWidth
              key="add-new-price-range-button"
              data-testid="add-new-price-range-button"
              type="secondary"
              size="normal"
              variant="contained"
              onClick={handleOnAddRange}
              disabled={priceRangeRows.length === 5}
            >
              <div className="hc-pr-min">Add Range</div>
              <EnterpriseIcon icon={PlusCircleIcon} size="sm" />
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default memo(PriceRangeSection)
