import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Grid, Typography } from '@mui/material'
import capitalize from 'lodash/fp/capitalize'

import ContentSpacer from 'components/common/ContentSpacer'
import { DialogEnum } from 'components/common/Dialog'
import EnhancedTable from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import {
  getEnhancedTablePageableProps,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'
import FilterBar from 'components/common/FilterBar'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import SelectFilter from 'components/common/FilterBar/SelectFilter'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import Link from 'components/common/Link'
import TableSpacer from 'components/common/TableSpacer'
import PromotionStatusChip from '../PromotionStatusChip'

import { isOneOfUserRoles } from 'services/authorization'
import { getPromotions } from 'services/promotions'
import { Direction } from 'services/pageableHelper'
import {
  formatDateLocalTime,
  formatDateRange,
  DATE_DISPLAY_FORMAT,
  formatDate,
  DATE_FORMAT_MONTH_DAY,
} from 'services/dateService'
import { RoutePath } from 'services/NavigationHelper'
import { USER_ROLE_ADMIN, USER_ROLE_PROGRAM_MANAGER } from 'services/roles'

import { openDialog } from 'store/dialog/actionCreator'

import {
  MerchandiseHierarchy,
  Promotion,
  PromotionStatus,
  UpdatedPromotion,
} from 'types/Promotion'
import { SelectOption } from 'types/SelectOption'

import { pyramids } from 'constants/pyramids'

import startCase from 'lodash/fp/startCase'
import toLower from 'lodash/fp/toLower'

import {
  currentSeller,
  getMemberOf,
  isRoleExternalUserSelector,
} from 'store/selectors'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'
import { ReportType } from 'types/Report'
import { GenerateReportRequest, submitReport } from 'services/reports'
import { flag, FlagName } from 'flag'
import PromotionFilters from '../PromotionFilters'
import { getDateAddedByDays } from 'v2/utils/date'

const today = formatDateLocalTime(new Date(), DATE_DISPLAY_FORMAT)
const isPromoVisibiltyEnabled = flag(FlagName.PROMO_VISIBILITY)

type TableState = {
  page: number
  perPage: number
  direction: Direction
  orderBy: string
}

type SearchParams = TableState & {
  division_id: string | undefined
  pyramid_id: string | undefined
  promotion_id: string | undefined
  promotion_status: string | undefined
  effective_start_date: string | undefined
  effective_end_date: string | undefined
  upstream_status: string | undefined
  review_milestone: string | undefined
}

const initialSearchParams: SearchParams = {
  page: 0,
  perPage: 100,
  direction: Direction.DESC,
  orderBy: 'start_date',
  division_id: undefined,
  pyramid_id: undefined,
  promotion_id: undefined,
  promotion_status: undefined,
  effective_start_date: today,
  effective_end_date: isPromoVisibiltyEnabled
    ? formatDateLocalTime(
        getDateAddedByDays(new Date(), 30),
        DATE_DISPLAY_FORMAT,
      )
    : undefined,
  upstream_status: undefined,
  review_milestone: undefined,
}

export const formatDiscount = (discountType: string, discountValue: number) => {
  if (!discountType) return
  if (discountType === 'PercentageOff') return `${discountValue}%`
  else if (discountType === 'FixedPrice' || discountType === 'DollarOff')
    return `$${discountValue}`
}

export const PromotionsList = () => {
  const reduxDispatch = useDispatch()
  const seller = useSelector(currentSeller)
  const memberOf = useSelector(getMemberOf)
  const isExternalUser = useSelector(isRoleExternalUserSelector)

  const [pending, setPending] = useState(true)
  const [refreshCount, setRefreshCount] = useState(0)
  const [promotions, setPromotions] = useState<UpdatedPromotion[]>([])

  const [total, setTotal] = useState(0)

  const getPyramidOptions = () => {
    let options: SelectOption[] = []

    pyramids.map((pyramid) =>
      options.push({
        name: pyramid.group_name,
        value: pyramid.group_id,
      }),
    )

    return options
  }

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)

  const handleFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  const getReviewMilestoneStatus = useCallback(
    (promotion: UpdatedPromotion) => {
      const isCategoryManagerApproved =
        promotion.category_manager_approval_status === 'APPROVED'
      const isPpcoeAgreementApproved =
        promotion.ppcoe_agreement_approval_status !== undefined
      const isSiteExperienceApproved =
        promotion.site_experience_approval_status !== undefined
      if (
        isCategoryManagerApproved &&
        isPpcoeAgreementApproved &&
        isSiteExperienceApproved
      ) {
        return 'Review Complete'
      }
      if (!isCategoryManagerApproved || !isPpcoeAgreementApproved) {
        if (!isSiteExperienceApproved) {
          return 'Category Management & Site Experience'
        }
        return 'Category Management'
      }
      if (!isSiteExperienceApproved) {
        return 'Site Experience'
      }
    },
    [],
  )

  useEffect(() => {
    let mounted = true

    setPending(true)

    getPromotions(
      {
        direction: searchParams.direction,
        orderBy: searchParams.orderBy,
        page: searchParams.page,
        perPage: searchParams.perPage,
      },
      {
        divisionId: searchParams.division_id,
        pyramidId: searchParams.pyramid_id,
        promotionId: searchParams.promotion_id,
        promotionStatus: searchParams.promotion_status,
        effectiveDate: formatDateRange(
          searchParams.effective_start_date,
          searchParams.effective_end_date,
        ),
        sellerId: seller?.id,
        upstreamStatus: searchParams.upstream_status,
        reviewMilestone: searchParams.review_milestone,
      },
      isExternalUser,
    ).then((promotions) => {
      if (mounted) {
        setPromotions(promotions.data)
        setTotal(promotions.total)
        setPending(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [searchParams, seller, refreshCount, isExternalUser])

  const fieldList = [
    {
      key: 'priority_label',
      heading: 'Priority',
      formatCell: (item: UpdatedPromotion) => {
        return <Typography>{item.priority_label}</Typography>
      },
      hasSort: true,
    },
    {
      key: 'umbrella_label',
      heading: isExternalUser
        ? 'Promotional Message'
        : 'Umbrella (Promo Message)',
    },
    {
      key: 'details',
      heading: 'Discount',
      formatCell: (item: UpdatedPromotion) => {
        return (
          <>
            {item.details.map((i, index) => (
              <div key={index}>
                <Typography>{startCase(i.discount_type)}</Typography>
                <Typography>
                  {formatDiscount(i.discount_type, i.discount_value)}
                </Typography>
              </div>
            ))}
          </>
        )
      },
    },
    {
      key: 'division',
      heading: 'Division',
      formatCell: (item: UpdatedPromotion) => {
        return (
          <>
            {item.merchandise_hierarchies.map(
              (i, index) =>
                i.division_name && (
                  <div key={index}>
                    <Typography>
                      {startCase(toLower(i.division_name))}
                    </Typography>
                  </div>
                ),
            )}
          </>
        )
      },
    },
    {
      key: 'start_date',
      heading: 'Start Date',
      hasSort: true,
      formatCell: formatDateMDYT('start_date'),
    },
    {
      key: 'end_date',
      heading: 'End Date',
      hasSort: true,
      formatCell: formatDateMDYT('end_date'),
    },
  ]

  if (!isExternalUser) {
    fieldList.unshift({
      key: 'name',
      heading: 'Promo ID & Name',
      formatCell: (item: UpdatedPromotion) => {
        if (hasEdit) {
          return (
            <>
              <Typography
                sx={{ cursor: 'pointer' }}
                color="primary"
                onClick={() => openEditPromotionDialog(item)}
                data-testid="edit-promotion-link"
              >
                {item.promotion_id}
              </Typography>
              <Typography>{item.name}</Typography>
            </>
          )
        } else {
          return (
            <>
              <Typography>{item.promotion_id}</Typography>
              <Typography>{item.name}</Typography>
            </>
          )
        }
      },
    })
    fieldList.push({
      key: 'status',
      heading: 'Status',
      hasSort: true,
      formatCell: (item: UpdatedPromotion) => (
        <PromotionStatusChip
          status={item.status}
          upstreamStatus={item.upstream_status}
        />
      ),
    })
  }

  if (isPromoVisibiltyEnabled) {
    fieldList.splice(
      !isExternalUser ? fieldList.length - 1 : fieldList.length,
      0,
      {
        key: 'review_milestones',
        heading: 'Review Milestones',
        formatCell: (item: UpdatedPromotion) => (
          <>{getReviewMilestoneStatus(item)}</>
        ),
      },
    )
    fieldList.splice(!isExternalUser ? 2 : 1, 1, {
      key: 'promo_message',
      heading: 'Promo Message',
      formatCell: (item: UpdatedPromotion) => (
        <>
          {item.promotion_description
            ? item.promotion_description
            : item.umbrella_label}
        </>
      ),
    })
    fieldList.splice(!isExternalUser ? 1 : 0, 1)
    fieldList.splice(1, 0, {
      key: 'pyramid',
      heading: 'Pyramid',
      formatCell: (item: UpdatedPromotion) => (
        <>
          {item.merchandise_hierarchies.map((merch: MerchandiseHierarchy) => (
            <p className="hc-mb-none">{capitalize(merch.pyramid_name)}</p>
          ))}
        </>
      ),
    })
  }

  const refreshData = () => {
    setRefreshCount((prev: number) => prev + 1)
  }

  const openEditPromotionDialog = (element: Promotion) => {
    reduxDispatch(
      openDialog({
        dialogEnum: isPromoVisibiltyEnabled
          ? DialogEnum.NEW_EDIT_PROMOTION_DIALOG
          : DialogEnum.EDIT_PROMOTION_DIALOG,
        componentProps: {
          promotion: element,
          refreshData,
        },
        closeCallback: refreshData,
      }),
    )
  }

  const handleCreateReport =
    ({ type, reportName }: GenerateReportRequest) =>
    () => {
      return submitReport({
        type,
        parameters: getReportParams(),
        sellerId: seller?.id,
        reportName,
      })
    }

  const getReportParams = () => {
    let parameters = {}
    if (searchParams.effective_start_date && searchParams.effective_end_date) {
      parameters = {
        effective_date: formatDateRange(
          searchParams.effective_start_date,
          searchParams.effective_end_date,
        ),
      }
    }

    return parameters
  }

  const handleDownload = () => {
    const reportType = ReportType.PROMOTIONS
    reduxDispatch(
      openDialog({
        dialogEnum: DialogEnum.REPORT_DOWNLOAD_DIALOG,
        componentProps: {
          title: 'GENERATING REPORT...PLEASE WAIT',
          reportTitle: 'Promotions',
          sellerId: seller?.id,
          createReport: handleCreateReport({
            type: reportType,
            reportName: `${formatDate(
              searchParams.effective_start_date,
              DATE_FORMAT_MONTH_DAY,
              DATE_DISPLAY_FORMAT,
            )} - ${formatDate(
              searchParams.effective_end_date,
              DATE_FORMAT_MONTH_DAY,
              DATE_DISPLAY_FORMAT,
            )}`,
          }),
        },
      }),
    )

    trackCustomEvent(FireflyEvent.PROMOTIONS_PARTNER_DOWNLOAD, 'event', 'click')
  }

  const hasEdit = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
  ])

  const source = 'direct'

  return (
    <div>
      <Typography>
        Target+ partners can participate in Target+ eligible promotions. Visit{' '}
        <Link
          to={`${RoutePath.KNOWLEDGE_ARTICLE}/000100035`}
          onClick={() =>
            trackCustomEvent(
              FireflyEvent.PROMOTIONS_HELPCENTER,
              source,
              '000100035',
              'Promotions Overviews',
            )
          }
        >
          this Help Center article
        </Link>{' '}
        to learn how.
      </Typography>
      <ContentSpacer />

      <FilterBar
        count={total}
        onClear={handleFilterClear}
        onDownload={seller?.id ? handleDownload : undefined}
        appliedFilterCount={appliedFilterCount}
      >
        {isPromoVisibiltyEnabled ? (
          <PromotionFilters
            searchParams={searchParams}
            searchParamActions={searchParamActions}
            pyramidActions={getPyramidOptions()}
          />
        ) : (
          <>
            <Grid container spacing={2} sx={{ p: 2 }}>
              {!seller && (
                <Grid item xs={3}>
                  <MultiSelectFilter
                    label="Promotion Status"
                    placeholder="Promotion Status"
                    searchParam="promotion_status"
                    onChange={searchParamActions.updateSearchParam}
                    value={searchParams.promotion_status}
                    data={[
                      {
                        name: 'Partner Visible',
                        value: PromotionStatus.PARTNER_VISIBLE,
                      },
                      {
                        name: 'Partner Not Visible',
                        value: PromotionStatus.PARTNER_NOT_VISIBLE,
                      },
                    ]}
                  />
                </Grid>
              )}
              <Grid item xs={5}>
                <DateRangeFilter
                  label="Promo Timeframe"
                  startValue={searchParams.effective_start_date}
                  startSearchParam="effective_start_date"
                  endValue={searchParams.effective_end_date}
                  endSearchParam="effective_end_date"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
            </Grid>
            {!seller && (
              <>
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item xs={3}>
                    <TypeaheadFilter
                      label="Promotion ID"
                      value={searchParams.promotion_id}
                      searchParam="promotion_id"
                      onChange={searchParamActions.updateSearchParam}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <SelectFilter
                      label="Pyramid"
                      placeholder="Group (Pyramid)"
                      searchParam="pyramid_id"
                      onChange={searchParamActions.updateSearchParam}
                      value={searchParams.pyramid_id}
                      data={getPyramidOptions()}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TypeaheadFilter
                      label="Division"
                      value={searchParams.division_id}
                      searchParam="division_id"
                      onChange={searchParamActions.updateSearchParam}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <SelectFilter
                      label="Show Deactivated Only"
                      placeholder="Show Deactivated Only"
                      searchParam="upstream_status"
                      onChange={searchParamActions.updateSearchParam}
                      value={searchParams.upstream_status}
                      data={[
                        {
                          name: 'Yes',
                          value: 'deactivated',
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </FilterBar>

      <TableSpacer>
        <EnhancedTable
          data={promotions}
          fieldList={fieldList}
          isLoading={pending}
          total={total}
          {...getEnhancedTablePageableProps(searchParams, searchParamActions)}
        />
      </TableSpacer>
    </div>
  )
}

export default PromotionsList
