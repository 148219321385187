import styled from '@emotion/styled'
import { Card, Grid, Button } from '@enterprise-ui/canvas-ui-react'

export const StyledServiceCard = styled(Card)`
  height: 100%;

  &.C-Card {
    border: 1px solid
      ${(props: { isActive?: boolean; isServiceOpted?: boolean }) =>
        props.isActive
          ? props.isServiceOpted
            ? 'var(--canvas-border-color--success)'
            : 'var(--canvas-border-color--focus)'
          : props.isServiceOpted
          ? 'var(--canvas-border-color--focus)'
          : 'var(--canvas-background-color--placeholder)'};
  }
  position: relative;
`
export const StyledSubmitButton = styled(Button)`
  &.C-Button {
    width: 200px;
  }
`
export const StyledGridContainer = styled(Grid.Container)`
  .C-GridItem {
    height: inherit;
  }
`

export const StyledToggle = styled.div`
  position: absolute;
  bottom: 12px;
`
