import orderBy from 'lodash/fp/orderBy'
import getOr from 'lodash/fp/getOr'
import has from 'lodash/fp/has'
import sortBy from 'lodash/fp/sortBy'

import {
  CATEGORY_SERVICE_LEVEL_AGREEMENT,
  CATEGORY_SHIPPING_SERVICE,
  PhysicalDisposition,
  FinancialDisposition,
} from 'constants/categories'

import {
  ReturnDescription,
  ReturnDescriptionAlias,
} from 'constants/returnPolicies'

import {
  Code,
  VendorCategory,
  ShippingService,
  ShippingServiceCode,
} from 'types/Seller'

export const getCategoryCodes = (
  categories: VendorCategory[],
  categoryId: string,
  order: 'asc' | 'desc' | undefined = 'asc',
): Code[] => {
  const category = categories.find((oneCategory) => {
    return oneCategory.category_id.trim() === categoryId.trim()
  })

  return category
    ? orderBy(
        'code_id',
        order,
        category.codes.map((code) => {
          const codeDescription = getOr('', 'code_description', code)
          const catCode = { ...code }
          catCode.code_description = getCodeDescriptionAlias(codeDescription)
          return catCode
        }),
      )
    : []
}

export const getCategoryCode = (
  categories: VendorCategory[],
  categoryId: string,
  codeId?: number,
): Code | undefined => {
  if (codeId) {
    const codes = getCategoryCodes(categories, categoryId)
    return codes.find((code) => code.code_id === codeId)
  }
  return undefined
}

export const getCodeDescription = (
  categories: VendorCategory[],
  categoryId: string,
  codeId?: number,
): string => {
  const code = getCategoryCode(categories, categoryId, codeId)
  return code && code.code_description
    ? getCodeDescriptionAlias(code.code_description)
    : ''
}

export const getCodeDescriptionAlias = (codeDescription: string): string => {
  switch (codeDescription) {
    case ReturnDescription.WRITE_OFF:
      return ReturnDescriptionAlias.TARGET_WRITE_OFF
    case ReturnDescription.RETURN_TO_VENDOR_CRC:
      return ReturnDescriptionAlias.RETURN_TO_PARTNER_CRC
    case ReturnDescription.CHARGE_TO_VENDOR:
      return ReturnDescriptionAlias.CHARGE_TO_PARTNER
    default:
      return codeDescription
  }
}

export const getFinancialDispositionByVmmId = (vmmId: number): string => {
  switch (vmmId) {
    case FinancialDisposition.WriteOff:
      return ReturnDescriptionAlias.TARGET_WRITE_OFF
    case FinancialDisposition.ChargeToPartner:
      return ReturnDescriptionAlias.CHARGE_TO_PARTNER
    default:
      return vmmId.toString()
  }
}

export const getPhysicalDispositionByVmmId = (vmmId: number): string => {
  switch (vmmId) {
    case PhysicalDisposition.Destroy:
      return 'Destroy'
    case PhysicalDisposition.ReturnViaCrc:
      return ReturnDescriptionAlias.RETURN_TO_PARTNER_CRC
    default:
      return vmmId.toString()
  }
}

export const getCodeName = (id: number | undefined, codes: Code[]): string => {
  if (!id) {
    return ''
  }
  const code = codes.find((code) => code.code_id === id)
  if (!code) return ''
  switch (code.code_name) {
    case 'Regional': {
      return 'USPS'
    }

    default:
      return code.code_name
  }
}

export const getBuildingLeadTimeName = (
  vendorCategories: VendorCategory[],
  buildingLeadTimeId: number,
): string => {
  const buildingLeadTimeCategoryCode = getCategoryCode(
    vendorCategories,
    CATEGORY_SERVICE_LEVEL_AGREEMENT,
    buildingLeadTimeId,
  )

  return buildingLeadTimeCategoryCode?.code_name || ''
}

export const getBuildingLeadTimeIdOverrideMessage = (
  serviceLevelAgreements: ShippingService[] | undefined,
  vendorCategories: VendorCategory[],
) => {
  if (!serviceLevelAgreements || !vendorCategories.length) {
    return ''
  }

  const serviceCodes = getCategoryCodes(
    vendorCategories,
    CATEGORY_SHIPPING_SERVICE,
  )

  let names = []

  for (let i = 0; i < serviceLevelAgreements.length; i++) {
    const sla = serviceLevelAgreements[i]
    const isLast = i === serviceLevelAgreements.length - 1

    for (let j = 0; j < serviceCodes.length; j++) {
      const code = serviceCodes[j]

      if (
        has('building_lead_time_id_override', sla) &&
        sla.id === code.code_id
      ) {
        let name = code.code_name.toLowerCase()

        if (names.length && isLast) {
          name = `and ${name}`
        }

        names.push(name)
      }
    }
  }

  if (names.length) {
    return `Order capacity per day has been exceeded and ${names.join(
      ', ',
    )} building lead ${
      names.length > 1 ? 'times' : 'time'
    } will be adjusted daily until your order volume is at or below capacity.`
  } else {
    return ''
  }
}

export const getOrderedServiceLevels = (
  serviceLevels?: Array<{ id: number }>,
) => {
  if (!serviceLevels) {
    return []
  }

  const serviceLevelOrder = [
    ShippingServiceCode.EXPRESS,
    ShippingServiceCode.STANDARD,
  ]

  const sortedServiceLevels = sortBy((item) => {
    return serviceLevelOrder.indexOf(item.id)
  }, serviceLevels)

  return sortedServiceLevels
}
