import { format } from 'date-fns'
import { datePattern } from 'v2/constant/date'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { postSellerCardExecution } from 'v2/services'
import { ReturnRate } from 'v2/types/returnRateResponse'

const returnRateQuery = async ({
  divisionId,
  categoryId,
  interval,
  sellerId,
  vmmId,
  cardId,
}: {
  divisionId: string | null
  categoryId: string | null
  interval: string
  sellerId: string
  vmmId: string
  cardId: number
}) => {
  const config = {
    sellerId,
    cardId,
    payload: {
      filters: [
        {
          dimension: 'vendor_id',
          pattern: String(vmmId),
          type: 'in',
        },
        ...(divisionId
          ? [
              {
                dimension: 'division_id',
                pattern: divisionId,
                type: 'in',
              },
            ]
          : []),
        ...(categoryId
          ? [
              {
                dimension: 'group_id',
                pattern: categoryId,
                type: 'in',
              },
            ]
          : []),
      ],
      time_period: {
        interval,
        calendar_type: 'Fiscal',
        type: 'relative',
      },
    },
  }

  const data = await postSellerCardExecution(config)

  if (cardId === GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_DAY) {
    const transformedResponse = data.map((res: ReturnRate) => ({
      ...res,
      x_axis: format(new Date(res.x_axis), datePattern.slash.MM_dd_yyyy),
    }))
    return transformedResponse
  }

  return data
}

export default returnRateQuery
