import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import { TableHook } from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'
import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { ReferralPercentage } from 'types/ReferralFee'
import { formatPercentage } from '../helpers'

interface Props {
  table: TableHook
  data: ReferralBasePercentage[] | ReferralPercentage[]
  total: number
  isPending: boolean
}

const fieldList: EnhancedTableFieldType<
  ReferralBasePercentage | ReferralPercentage
>[] = [
  {
    key: 'last_modified',
    heading: 'Last Modified Date',
    formatCell: formatDateMDYT('last_modified'),
  },
  {
    key: 'last_modified_by',
    heading: 'Last Modified By',
  },
  {
    key: 'referral_percentage',
    heading: 'Base Fee',
    formatCell: (rowItem) => (
      <div>
        {rowItem.referral_percentage === 0
          ? 'NA'
          : `${formatPercentage(rowItem.referral_percentage)}%`}
      </div>
    ),
  },
  {
    key: 'price_range_referral_percentages',
    heading: 'Referral Fees',
    formatCell: (rowItem) =>
      rowItem.price_range_referral_percentages
        ? rowItem.price_range_referral_percentages?.map((item) => {
            const referral_percentage =
              item?.referral_percentage &&
              formatPercentage(Number(item?.referral_percentage))
            return (
              <div className="hc-pb-min">
                {item.min_price}$-{item.max_price}$ : {referral_percentage}%
              </div>
            )
          })
        : 'NA',
  },
]

export const ChangeHistoryTable = ({
  table,
  data,
  total,
  isPending,
}: Props) => {
  return (
    <TableSpacer>
      <EnhancedTable
        total={total}
        stripedRows
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table.actions.changePerPage}
        onRequestSort={table.actions.sort}
        orderBy={table.state.orderBy}
        order={table.state.direction}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        fieldList={fieldList}
        data={data}
        isLoading={isPending}
        isUpdatedTable
      />
    </TableSpacer>
  )
}

export default ChangeHistoryTable
