import axios, { CancelToken as CancelTokenType } from 'axios'

import {
  Direction,
  getPageable,
  Pageable,
  PagingParams,
} from './pageableHelper'

import apiConfig from 'config/apiConfig'

import { getMarketplaceProductByTcin } from './items'
import { getUniqueValues } from './getUniqueValues'

import { CollectionResponse } from 'types/Response'

import {
  Return,
  ReturnWithDetail,
  OrderSearchParams,
  ReturnSearchParams,
} from 'types/Orders'

type Config = {
  params: Pageable & ReturnSearchParams
  cancelToken?: CancelTokenType
}

const CancelToken = axios.CancelToken
let cancel: any

export async function searchSellerProductReturns(
  pagingParams: PagingParams,
  searchParams: ReturnSearchParams = {},
  cancelParams?: {
    noCancel: boolean
  },
): Promise<CollectionResponse<Return>> {
  if (cancel) {
    cancel()
  }

  const cancelToken = new CancelToken(function executor(c) {
    cancel = c
  })

  const { seller_id: sellerId } = searchParams
  const pageable = getPageable(pagingParams)

  const config: Config = {
    params: {
      ...pageable,
      ...searchParams,
      ...(searchParams.seller_id && { seller_id: undefined }), // no need to add seller_id query
    },
    cancelToken,
  }

  if (cancelParams?.noCancel) {
    delete config.cancelToken
  }

  const response = await axios.get(
    `${apiConfig.sms}/sellers/${sellerId}/product_returns_search`,
    config,
  )
  const { data } = response

  return {
    data: data.items,
    total: data.total_count,
  }
}

export async function getReturnQuantity(
  searchParams: ReturnSearchParams = {},
): Promise<number> {
  let page = 0
  const perPage = 200
  const firstPage = await searchProductReturns(
    {
      page,
      perPage,
    },
    searchParams,
  )
  const total = firstPage.total
  let data = [...firstPage.data]
  while (data.length < total) {
    page += 1
    const nextPage = await searchProductReturns(
      {
        page,
        perPage,
      },
      searchParams,
    )
    data = data.concat(nextPage.data)
  }

  var quantityTotal = data.reduce(
    (accum, returnOrder) => accum + returnOrder.quantity,
    0,
  )

  return quantityTotal
}

export async function searchProductReturns(
  pagingParams: PagingParams,
  searchParams: ReturnSearchParams = {},
): Promise<CollectionResponse<Return>> {
  const pageable = getPageable(pagingParams)
  const { seller_id: sellerId } = searchParams
  const config = {
    params: {
      ...pageable,
      ...searchParams,
    },
  }
  let url = `${apiConfig.sms}/product_returns_search`

  if (sellerId) {
    url = `${apiConfig.sms}/sellers/${sellerId}/product_returns_search`
  }

  const response = await axios.get(url, config)

  const { data } = response

  return {
    data: data.items,
    total: data.total_count,
  }
}

export function getProductReturns({
  direction = Direction.ASC,
  orderBy,
  page,
  perPage,
  sellerId,
}: {
  direction: Direction.ASC | Direction.DESC | undefined
  orderBy?: string
  page: number
  perPage: number
  sellerId?: string
}) {
  const config = {
    params: getPageable({ page, perPage, orderBy, direction }),
  }

  return axios
    .get(`${apiConfig.sms}/sellers/${sellerId}/product_returns`, config)
    .then((res: { data: Return[]; headers: any }) => {
      const { data, headers } = res
      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        total,
        data,
      }
    })
}

export const getProductReturn = (sellerId: string, productReturnId: string) => {
  return axios
    .get(
      `${apiConfig.sms}/sellers/${sellerId}/product_returns/${productReturnId}`,
    )
    .then((response) => response.data as Return)
}

export async function getReturnWithDetail(
  pagingParams: PagingParams,
  searchParams: OrderSearchParams = {},
): Promise<ReturnWithDetail[]> {
  const { data } = await searchSellerProductReturns(pagingParams, searchParams)

  const tcins = getUniqueValues<Return>(data, 'tcin')

  const products = await Promise.all(
    tcins.map((tcin) => getMarketplaceProductByTcin(tcin)),
  )

  const returnWithDetail = data.map((item) => {
    const product = products.find((p) => p?.tcin === item.tcin)

    return {
      ...item,
      product_id: product?.product_id ?? '',
      productTitle: product?.title ?? '',
      image: product?.primary_image ?? '',
    }
  })

  return returnWithDetail
}
