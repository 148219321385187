type PromotionDetails = {
  discount_type: string
  discount_value: number
  location_ids: number[]
}

export enum PromotionStatus {
  PARTNER_VISIBLE = 'PARTNER_VISIBLE',
  PARTNER_NOT_VISIBLE = 'PARTNER_NOT_VISIBLE',
}

export type MerchandiseHierarchy = {
  pyramid_id: number
  pyramid_name: string
  division_id?: number
  division_name?: string
}

export type Promotion = {
  id: string
  promotion_id: string
  status: string
  upstream_status: string
  channel: string
  name: string
  type: string
  priority_label?: string
  umbrella_label?: string
  details: PromotionDetails[]
  start_date: string
  end_date: string
  created?: string
  last_modified?: string
  last_modified_by?: string
  merchandise_hierarchies: MerchandiseHierarchy[]
}

export type UpdatedPromotion = {
  id: string
  promotion_id: string
  status: string
  upstream_status: string
  channel: string
  name: string
  type: string
  priority_label?: string
  umbrella_label?: string
  details: PromotionDetails[]
  start_date: string
  end_date: string
  created?: string
  last_modified?: string
  last_modified_by?: string
  merchandise_hierarchies: MerchandiseHierarchy[]
  category_manager_approval_status?: string
  ppcoe_agreement_approval_status?: string
  site_experience_approval_status?: string
  item_type_restrictions?: boolean
  allowed_product_sub_types?: {
    sub_type_id: string
    sub_type_name: string
    allowed_item_types_ids: string[]
  }[]
  promotion_description?: string
  participation_end_date?: string
  promotion_notes?: string
  internal_review_notes?: string
}

export interface AllowedProductSubType {
  id: string
  name: string
  slectedItemTypeId: string[]
  selectedItemTypes?: {
    id: string
    label: string
    value: string
  }[]
}
