export interface ErrorCode {
  reason: string
  created: string
  created_by: string
  error_code: number
  last_modified: string
  error_category: string
  error_severity: string
  error_resolution?: string
  last_modified_by: string
  optionalNotes?: string
  status: string
}

export enum Severity {
  CRITICAL = 'CRITICAL',
  WARNING = 'WARNING',
  NON_CRITICAL = 'NON_CRITICAL',
}
