import { useState, useEffect } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import TitleBar from 'components/common/TitleBar'
import { StyledSubtitle } from './styles'
import TwoDayUploadCard from './TwoDayUploadCard'
import { FlagName, flag } from 'flag'
import EnableTwoDayDrawer from './EnableTwoDayDrawer'
import { TWO_DAY_ITEM, TWO_DAY_STATE } from './constants'
import { useStateValue } from 'stateManager/StateProvider'
import useInterval from 'hooks/useInterval'
import { Report, ReportStatus, ReportType } from 'types/Report'
import { getTwoDayUpload, pollReport, saveReportAsFile } from 'services/reports'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from 'store/notification/reducer'
import { Direction } from 'services/pageableHelper'
import { currentSellerId } from 'store/selectors'

export interface DrawerType {
  title: string
  name: string
  subtitle: string
  dropText: string
  reportType: string
  type: string
}

export const EnableTwoDay = () => {
  const { state, updateState } = useStateValue()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [drawerType, setDrawerType] = useState<DrawerType>(TWO_DAY_STATE)
  const isTwoDayTNTEnabled = flag(FlagName.ENABLE_TWO_DAY_TNT)

  const sellerId = useSelector(currentSellerId)
  const isItemUpload = drawerType.name === TWO_DAY_ITEM.name

  const onUploadFileClick = (drawerName: string) => {
    setIsDrawerOpen(true)
    if (drawerName === TWO_DAY_STATE.title) setDrawerType(TWO_DAY_STATE)
    else setDrawerType(TWO_DAY_ITEM)
  }

  const onRequestClose = () => {
    updateState({
      completedReport: undefined,
    })
    setIsDrawerOpen(false)
  }

  const dispatch = useDispatch()

  useInterval(
    () => {
      if (!state.inProgressReport) return

      pollReport(state.inProgressReport.id).then(async (report: Report) => {
        if (
          report.status === ReportStatus.PENDING ||
          report.status === ReportStatus.PROCESSING
        ) {
          return
        }
        if (report?.feedback?.[0].includes('completed without errors')) {
          dispatch(
            showNotification({
              isShown: true,
              message: `Your upload is successful. You can also check the settings on the Shipping Details Page`,
              severity: 'success',
              autoClose: true,
            }),
          )
          onRequestClose()
        } else if (report.status === ReportStatus.COMPLETE) {
          updateState({
            completedReport: report,
          })
        }
        updateState({
          inProgressReport: undefined,
          isLoading: false,
        })
      })
    },
    state.inProgressReport ? 5000 : null,
  )

  useInterval(
    () => {
      if (!state.currentDataReport) return

      pollReport(state.currentDataReport.id).then(async (report: Report) => {
        if (
          report.status === ReportStatus.PENDING ||
          report.status === ReportStatus.PROCESSING
        ) {
          return
        }
        if (report.status === ReportStatus.COMPLETE) {
          saveCurrentReport(report)
        }
        updateState({
          isCurrentDataLoading: false,
          currentDataReport: undefined,
        })
      })
    },

    state.currentDataReport ? 5000 : null,
  )

  const saveCurrentReport = (report: Report) => {
    if (report) {
      const saveFileParams = {
        report: report,
        title: `Current_Data_${
          isItemUpload ? TWO_DAY_ITEM.name : TWO_DAY_STATE.name
        }`,
        hasDateRange: false,
      }
      saveReportAsFile(saveFileParams)
    }
  }

  useEffect(() => {
    getTwoDayUpload(
      {
        page: 0,
        perPage: 100,
        direction: Direction.DESC,
        orderBy: 'created',
      },
      {
        sellerId,
        type: isItemUpload
          ? ReportType.BULK_UPDATE_TWO_DAY_SHIPPING_PRODUCTS
          : ReportType.BULK_UPDATE_TWO_DAY_SHIPPING_STATES,
      },
    ).then((reports: any) => {
      const inProgress = reports.find(
        (report: Report) =>
          report.status === ReportStatus.PENDING ||
          report.status === ReportStatus.PROCESSING,
      )
      updateState({ inProgressReport: inProgress, isLoading: true })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getTwoDayUpload(
      {
        page: 0,
        perPage: 100,
        direction: Direction.DESC,
        orderBy: 'created',
      },
      {
        sellerId,
        type: isItemUpload ? TWO_DAY_ITEM.type : TWO_DAY_STATE.type,
      },
    ).then((reports: any) => {
      const inProgress = reports.find(
        (report: Report) =>
          report.status === ReportStatus.PENDING ||
          report.status === ReportStatus.PROCESSING,
      )
      updateState({ currentDataReport: inProgress, isCurrentDataLoading: true })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid.Container>
        <Grid.Item xs={12}>
          <TitleBar
            title="Enable 2-Day Transit Time"
            variant="h3"
            hideDivider
          />
          <StyledSubtitle>
            Override other shipping configurations to apply the 2-day transit
            time for selected nodes, states, and TCINs. If a node is in rollover
            and the BLT is systematically over 24 hours, the 2-day capability
            will not be functional. Once the node reverts to a 24-hour BLT with
            no rollover, the 2-day capability will be restored
          </StyledSubtitle>
        </Grid.Item>
        <Grid.Item xs={12}>
          <Grid.Container spacing="dense">
            <Grid.Item xs={6}>
              <TwoDayUploadCard
                title={TWO_DAY_STATE.title}
                subtitle={TWO_DAY_STATE.subtitle}
                onUploadFileClick={onUploadFileClick}
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <TwoDayUploadCard
                title={TWO_DAY_ITEM.title}
                subtitle={TWO_DAY_ITEM.subtitle}
                onUploadFileClick={onUploadFileClick}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      {isDrawerOpen && isTwoDayTNTEnabled && (
        <EnableTwoDayDrawer
          drawerType={drawerType}
          isDrawerOpen={isDrawerOpen}
          onRequestClose={onRequestClose}
        />
      )}
    </>
  )
}

export default EnableTwoDay
