import React, { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BaseReferralFeeTable from './BaseReferralFeeTable'
import HeaderTitle from 'components/common/HeaderTitle'
import useTable from 'components/common/EnhancedTable/useTable'

import { getReferralBasePercentages } from 'services/referralBasePercentages'
import { Direction } from 'services/pageableHelper'

import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { CollectionResponse } from 'types/Response'
import Attribute, { ITEM_SUBTYPE } from 'types/Attribute'
import { TaxonomyTypeahead } from 'components/common/Typeahead'
import { Button, ButtonProps, Grid } from '@enterprise-ui/canvas-ui-react'
import { USER_ROLE_ADMIN, USER_ROLE_OPS } from 'services/roles'
import { isOneOfUserRoles } from 'services/authorization'
import { getMemberOf } from 'store/selectors'
import { EditReferralFeesDrawer } from 'components/ReferralFeesV2/EditReferralFeesDrawer'
import { FlagName, flag } from 'flag'
import { DialogEnum } from 'components/common/Dialog'
import { openDialog } from 'store/dialog/actionCreator'
import TitleBar from 'components/common/TitleBar'
import EditButton from 'components/common/EditButton'
import { PriceRangePayload } from 'components/ReferralFeesV2/types'
import { StyledText } from './styles'

export const BaseReferralFeesPage = () => {
  const [pending, setPending] = React.useState(false)
  const [referralFees, setReferralFees] = React.useState<
    ReferralBasePercentage[]
  >([])
  const [total, setTotal] = React.useState(0)

  const [isEditFlyoutOpen, setIsEditFlyoutOpen] = useState(false)

  const memberOf = useSelector(getMemberOf)
  const reduxDispatch = useDispatch()
  const [reloadToggle, setReloadToggle] = React.useState(false)

  const [subtypeId, setSubtypeId] = useState('')

  const [title, setTitle] = useState('')
  const [percent, setPercent] = useState<Nullable<string>>(null)
  const [attribute, setAttribute] = useState<Nullable<Attribute>>(null)

  const isPriceRangeEnabled = flag(FlagName.REFERRAL_FEE_WITH_PRICE_RANGE)
  const [priceRangeRows, setPriceRangeRows] = useState<PriceRangePayload[]>([])

  const handleFormChange =
    ({ type }: { type: 'attribute' | 'percent' }) =>
    (value: Nullable<Attribute | Nullable<string>>) => {
      if (type === 'attribute') {
        setAttribute(value as Nullable<Attribute>)
      } else if (type === 'percent') {
        setPercent(value as Nullable<string>)
      }
    }

  const handleReferralFeeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPercent(e.target.value)
  }

  const { table } = useTable({
    direction: Direction.DESC,
    orderBy: 'start_date',
    page: 0,
    perPage: 20,
  })

  React.useEffect(() => {
    if (!isEditFlyoutOpen || reloadToggle) {
      setPending(true)
      getReferralBasePercentages(table.state, true, subtypeId).then(
        (res: CollectionResponse<ReferralBasePercentage>) => {
          setReferralFees(res.data)
          setTotal(res.total)
          setPending(false)
        },
      )
    }
  }, [table.state, subtypeId, isEditFlyoutOpen, reloadToggle])

  const handleSubTypeChange = (value: any) => {
    if (!value) return
    setSubtypeId(value.id)
  }

  const handleFilterClear = () => {
    setSubtypeId('')
  }

  const handleOnEditClick = (
    title: string,
    rowItem?: ReferralBasePercentage,
  ) => {
    setIsEditFlyoutOpen(true)
    setTitle(title)
    if (rowItem) {
      setPercent((rowItem.referral_percentage * 100).toFixed())
      const attribute = {
        id: rowItem.sub_type_id,
        name: rowItem.subtypeName,
      }
      setAttribute(attribute)
      if (rowItem.price_range_referral_percentages) {
        const priceRangeRowsCopy = rowItem.price_range_referral_percentages
        priceRangeRowsCopy.forEach((item) => {
          item.referral_percentage =
            item.referral_percentage &&
            (Number(item.referral_percentage) * 100).toFixed(2)
        })
        setPriceRangeRows(priceRangeRowsCopy)
      }
    }
  }

  const handleCloseEditFlyout = () => {
    setIsEditFlyoutOpen(false)
    setPercent('')
    setAttribute(null)
    setPriceRangeRows([])
  }

  const handleViewHistory =
    (referralPercentage: ReferralBasePercentage) => () => {
      const { sub_type_id, subtypeName } = referralPercentage
      reduxDispatch(
        openDialog({
          dialogEnum: isPriceRangeEnabled
            ? DialogEnum.PRICE_RANGE_BASE_REFERRAL_FEE_HISTORY
            : DialogEnum.BASE_REFERRAL_FEE_HISTORY,
          componentProps: {
            subtypeId: sub_type_id,
            subtypeName,
          },
        }),
      )
    }

  const dialogClosedHandler = () => {
    setReloadToggle(!reloadToggle)
  }

  const canEditRoles = [USER_ROLE_ADMIN, USER_ROLE_OPS]

  const canEdit = isOneOfUserRoles(memberOf, canEditRoles)

  const AddNewBaseFee = ({ children }: ButtonProps) => (
    <Button
      children={children}
      key="add-new-base-fee-button"
      data-testid="add-new-base-fee-button"
      type="primary"
      size="normal"
      variant="contained"
      onClick={() => handleOnEditClick?.('Add New Base Fee')}
    />
  )

  return (
    <>
      <HeaderTitle title="Base Referral Fees" />
      {!isPriceRangeEnabled && (
        <TitleBar
          title="Base Referral Fees"
          actionButtons={[
            <EditButton
              key="edit-base-referral-fees"
              aria-label="edit-base-referral-fees"
              dialogComponent={DialogEnum.EDIT_REFERRAL_FEES}
              componentProps={{ isBaseReferralFee: true }}
              closeCallback={dialogClosedHandler}
              hide={!canEdit}
            />,
          ]}
        />
      )}
      <Grid.Container>
        <Grid.Item xs={12}>
          <StyledText>
            Subtype base referral fees will act as the default referral fee for
            items within each subtype. Referral fees can be overridden for
            specific item types on a per-partner basis in partner's Referral
            Fees.
          </StyledText>
        </Grid.Item>
        {isPriceRangeEnabled && (
          <Grid.Container className="hc-pt-xl" justify="space-between">
            <Grid.Item xs={4} className="hc-pl-lg">
              <TaxonomyTypeahead
                searchType={ITEM_SUBTYPE}
                placeholder="Search Subtype"
                onChange={handleSubTypeChange}
                onClear={handleFilterClear}
              />
            </Grid.Item>
            {canEdit && (
              <Grid.Item className="hc-pr-lg hc-pb-md">
                <AddNewBaseFee>ADD NEW BASE FEE</AddNewBaseFee>
              </Grid.Item>
            )}
          </Grid.Container>
        )}
        <Grid.Item xs={12}>
          <BaseReferralFeeTable
            table={table}
            data={referralFees}
            total={total}
            isPending={pending}
            canEdit={canEdit}
            handleOnEditClick={
              isPriceRangeEnabled ? handleOnEditClick : undefined
            }
            viewHistory={handleViewHistory}
          />
        </Grid.Item>
      </Grid.Container>
      {isEditFlyoutOpen && isPriceRangeEnabled && (
        <EditReferralFeesDrawer
          attribute={attribute}
          percent={percent ?? ''}
          title={title}
          isBaseReferralFee={true}
          isEditFlyoutOpen={isEditFlyoutOpen}
          handleCloseEditFlyout={handleCloseEditFlyout}
          onRequestChange={handleFormChange}
          handleReferralFeeChange={handleReferralFeeChange}
          priceRanges={priceRangeRows}
        />
      )}
    </>
  )
}

export default BaseReferralFeesPage
