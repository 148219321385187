import styled from '@emotion/styled'
import TargetPlus from 'components/common/Icons/TargetPlus'
import { Button } from '@enterprise-ui/canvas-ui-react'

interface StyledOptionsProps {
  isNavigationButton: boolean
  isInlineBlock: boolean
}

export const ChatBotContainer = styled.div`
  width: ${(props: { expand: boolean }) => (props.expand ? '690px' : '375px')};
  height: 600px;
  text-align: left;
`

export const StyledHeader = styled.div`
  display: flex;
  background-color: #000;
  justify-content: space-between;
  color: white;
`
export const StyledLogo = styled(TargetPlus)(({ theme }) => ({
  width: 32,
  height: 32,
  margin: theme.spacing(1, 0.5, 1, 1),
}))

export const StyledButton = styled(Button)`
  color: white;
`
export const LostConnectionPopup = styled.div`
  width: ${(props: { expand: boolean }) => (props.expand ? '690px' : '375px')};
  height: 251px;
  position: fixed;
  bottom: 0px;
  padding: 24px 0px 24px 0px;
  gap: 24px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  background: #ffffff;
  box-shadow: 0px -5px 8px -4px #00000014;
  box-shadow: 0px -12px 20px -4px #0000001f;
  z-index: 999;
`
export const BoldText = styled.div`
  font-weight: bold;
`

export const StyledOptions = styled.div<StyledOptionsProps>`
  display: ${(props) => (props.isInlineBlock ? 'inline-block' : 'block')};
  .C-Button {
    background-color: ${(props) =>
      props.isNavigationButton ? '#ddd' : '#F0F2F4'};
    color: #000;
    border: 1px solid #d3d5d8;
    text-align: left;
    padding: 7px;
    height: auto;
  }

  .C-Button:hover {
    background-color: ${(props) =>
      props.isNavigationButton ? '#d5d2d2' : '#e7e9ea'};
    color: #000;
    border: 1px solid #d3d5d8;
  }
`
export const CenterAlign = styled.div`
  justify-content: center;
`
export const StyledChatBotWindow = styled.div`
  overflow: ${(props: { diableScroll: boolean }) =>
    props.diableScroll ? 'hidden' : 'auto'};
  filter: ${(props: { diableScroll: boolean }) =>
    props.diableScroll ? 'blur(3px)' : 'unset'};
`
export const StyledToastMessage = styled.div`
  margin: auto;
  padding: 15px;
  width: 75%;
  font-size: 15px;
  border-radius: 10px;
  background: #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const StyledInitialisingLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: absolute;
  z-index: 999;
`
