import { FC } from 'react'
import { Spinner, Heading } from '@enterprise-ui/canvas-ui-react'
import { StyledInitialisingLoaderWrapper } from './styles'

const InitialisingLoader: FC = () => {
  return (
    <StyledInitialisingLoaderWrapper>
      <Heading size={4}>
        <Spinner size="inline" /> Loading
      </Heading>
    </StyledInitialisingLoaderWrapper>
  )
}

export default InitialisingLoader
