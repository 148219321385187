import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

import { AuthProvider } from '@praxis/component-auth'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import App from 'components/App'
import FullScreenLoader from 'components/common/loader/FullScreenLoader'
import { initializeFireFly } from 'services/fireflyInsights'
import * as serviceWorker from 'serviceWorker'
import configureHttpInterceptor from 'services/httpInterceptor'
import { shouldSendAuthoriationHeader } from 'services/security'

import { showNotification } from 'store/notification/reducer'
import { initStore } from 'store'

import oauthProviderConfig from 'config/oauthProviderConfig'
import ErrorBoundary from 'components/common/ErrorBoundary'
import themeConfig from 'config/themeConfig'

const store = initStore()
initializeFireFly()

configureHttpInterceptor((payload) => store.dispatch(showNotification(payload)))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  <ToastProvider location="top">
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeConfig}>
        <AuthProvider
          authorizationUrl={oauthProviderConfig.authorizationUrl}
          clientId={oauthProviderConfig.clientId}
          loadingIndicator={() => <FullScreenLoader isOpen />}
          loginRedirect={oauthProviderConfig.redirectUri}
          logoutUrl={oauthProviderConfig.logoutUrl}
          shouldSendHeaders={shouldSendAuthoriationHeader}
        >
          <ErrorBoundary page="Nested">
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
                <ErrorBoundary page="Nested-More">
                  <App />
                </ErrorBoundary>
              </QueryClientProvider>
            </Provider>
          </ErrorBoundary>
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ToastProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
