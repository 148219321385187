export enum GREEN_FIELD_CARD_ID {
  CATEGORY_FILTER = 2370182,
  DIVISION_FILTER = 2370183,
  ITEM_TYPE_FILTER = 2370184,
  TOP_SKU_TYPE_VC_VIEW = 2351505,
  TOP_SKU_TYPE_VAP_VIEW = 2351511,
  RETURN_RATE_CARD_MONTH = 2368022,
  RETURN_RATE_CARD_WEEK = 2368019,
  RETURN_RATE_CARD_DAY = 2368018,
  TOTAL_RETURNED_ITEMS_CARD = 2368702,
}
