import { useSelector } from 'react-redux'
import { Heading, Grid, Chip, Input } from '@enterprise-ui/canvas-ui-react'
import { StyledServiceCard, StyledToggle } from './styles'
import { isRoleExternalUserSelector } from 'store/selectors'

interface ServicesCardProps {
  title?: string
  content?: string
  icon?: string
  value?: boolean
  onChange: (event: any) => void
  isActive?: boolean
}
const ServicesCard = ({
  title,
  content,
  value,
  icon,
  onChange,
  isActive,
}: ServicesCardProps) => {
  const isExternalUser = useSelector(isRoleExternalUserSelector)

  return (
    <StyledServiceCard
      className="hc-pa-expanded"
      isActive={isActive}
      isServiceOpted={value}
    >
      <Grid.Container justify="space-between">
        <img
          alt=""
          className="hc-ma-normal"
          src={icon}
          height="50px"
          width="70px"
        />
        {isActive && (
          <Grid.Item>
            <Chip color="success">Active</Chip>
          </Grid.Item>
        )}
      </Grid.Container>

      <Heading className="hc-mt-dense" size={5}>
        {title}
      </Heading>
      <p className="hc-mt-dense hc-mb-2x">{content}</p>
      {isExternalUser && (
        <StyledToggle>
          <Input.Toggle
            aria-label={title}
            data-testid="toggle-button"
            label="Opt in"
            value={value}
            onChange={onChange}
          />
        </StyledToggle>
      )}
    </StyledServiceCard>
  )
}

export default ServicesCard
