import { FC, useState, useMemo } from 'react'
import {
  Heading,
  Grid,
  Button,
  Dropdown,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { ListUnorderedIcon } from '@enterprise-ui/icons'

import { useReturnInsightsStore } from 'v2/store'

interface ColumnOptions {
  selectAll: boolean
  upc: boolean
  division: boolean
  department: boolean
}

const AddColumns: FC = () => {
  const [columnSelections, setColumnSelections] = useState<ColumnOptions>({
    selectAll: false,
    upc: false,
    division: false,
    department: false,
  })
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const { updateTopSkuTypeColumns, selectedTopSkuTypeColumns } =
    useReturnInsightsStore()

  const isApplyEnabled = useMemo(
    () => Object.values(columnSelections).find((value) => value === true),
    [columnSelections],
  )

  const onColumnSelectionsUpdate = (selectedOptions: any) => {
    if ('selectAll' in selectedOptions) {
      setColumnSelections({
        selectAll: selectedOptions.selectAll,
        upc: selectedOptions.selectAll,
        division: selectedOptions.selectAll,
        department: selectedOptions.selectAll,
      })
    } else {
      setColumnSelections((prevState) => ({
        ...prevState,
        ...selectedOptions,
        selectAll: false,
      }))
    }
  }

  const onApplyClick = () => {
    updateTopSkuTypeColumns(columnSelections)
    setIsVisible(!isVisible)
  }

  const onClickCancel = () => {
    setColumnSelections(selectedTopSkuTypeColumns)
    setIsVisible(!isVisible)
  }

  const onEditIconClick = () => {
    setColumnSelections(selectedTopSkuTypeColumns)
    setIsVisible(!isVisible)
  }

  return (
    <Dropdown
      location="bottom-right"
      isVisible={isVisible}
      onRequestClose={() => null}
    >
      <Button type="secondary" onClick={onEditIconClick}>
        <EnterpriseIcon size="lg" icon={ListUnorderedIcon} />
      </Button>
      <Dropdown.Menu className="hc-pa-md">
        <Heading size={5} className="hc-mb-normal">
          Add Table Columns
        </Heading>
        <Input.Checkbox
          className="hc-mb-sm"
          id="selectAll"
          checked={columnSelections.selectAll}
          label="Select All"
          onUpdate={(key: any, value: any) =>
            onColumnSelectionsUpdate({ [key]: value })
          }
        />
        <Input.Checkbox
          checked={columnSelections.upc}
          className="hc-mb-sm"
          id="upc"
          label="UPC"
          onUpdate={(key: any, value: any) =>
            onColumnSelectionsUpdate({ [key]: value })
          }
        />
        <Input.Checkbox
          checked={columnSelections.division}
          className="hc-mb-sm"
          id="division"
          label="Division"
          onUpdate={(key: any, value: any) =>
            onColumnSelectionsUpdate({ [key]: value })
          }
        />
        <Input.Checkbox
          checked={columnSelections.department}
          className="hc-mb-sm"
          id="department"
          label="Department"
          onUpdate={(key: any, value: any) =>
            onColumnSelectionsUpdate({ [key]: value })
          }
        />
        <Grid.Container justify="flex-end" className="hc-mt-normal">
          <Grid.Item>
            <Button onClick={onClickCancel}>Cancel</Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              onClick={onApplyClick}
              disabled={!isApplyEnabled}
              type="primary"
            >
              Apply
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default AddColumns
