import { FC, useEffect } from 'react'
import { Heading, Grid, Dropdown, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import {
  useGreenFieldDivisionAutoComplete,
  useGreenFieldCategoryAutoComplete,
} from 'v2/hooks/autoComplete'
import { useReturnInsightsStore } from 'v2/store'
import { StyledFillterContainer, StyledDatePicker } from '../styles'

const ReturnInsightsFilter: FC = () => {
  const { onDivisionUpdate, divisionOptions, selectedDivisionOption } =
    useGreenFieldDivisionAutoComplete()
  const { categoryOptions, onCategoryUpdate, selectedCategoryOption } =
    useGreenFieldCategoryAutoComplete()
  const {
    updateDivisionId,
    updateTimeFrame,
    updateCategoryId,
    timeFrame,
    dateQuickSelectionRange,
  } = useReturnInsightsStore()

  useEffect(() => {
    updateDivisionId(selectedDivisionOption?.id ?? null)
  }, [updateDivisionId, selectedDivisionOption?.id])

  useEffect(() => {
    updateCategoryId(selectedCategoryOption?.id ?? null)
  }, [updateCategoryId, selectedCategoryOption?.id])

  const onDatePickerUpdate = (_id: string, value: any) => {
    if (value.startDate && value.endDate) {
      updateTimeFrame(value)
    }
  }

  return (
    <StyledFillterContainer className="hc-bg-grey07 hc-pa-normal">
      <Grid.Container justify="space-between">
        <Grid.Item>
          <Heading size={4}>Showing Details For :</Heading>
        </Grid.Item>
        <Grid.Item>
          <Grid.Container>
            <Grid.Item>
              <Button size="expanded" type="submit">
                HELP ARTICLES
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Dropdown>
                <Button size="expanded" type="ghost">
                  DOWNLOAD
                  <EnterpriseIcon icon={CaretDownIcon} size="md" />
                </Button>
                <Dropdown.Menu>
                  <Dropdown.MenuItem>For the current view</Dropdown.MenuItem>
                  <Dropdown.MenuItem>For all data</Dropdown.MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs={4}>
          <Autocomplete
            label="Division"
            placeholder="Select Division"
            options={divisionOptions}
            onUpdate={onDivisionUpdate}
          />
        </Grid.Item>
        <Grid.Item xs={4}>
          <Autocomplete
            placeholder="Select Category"
            label="Category"
            options={categoryOptions}
            onUpdate={onCategoryUpdate}
          />
        </Grid.Item>
        <Grid.Item xs={4}>
          <StyledDatePicker
            id="return-insights-date-picker"
            type="gregorian-range"
            label="Time Frame"
            onUpdate={onDatePickerUpdate}
            value={timeFrame}
            quickSelectRange={dateQuickSelectionRange}
          />
        </Grid.Item>
      </Grid.Container>
    </StyledFillterContainer>
  )
}

export default ReturnInsightsFilter
