import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'

const stageGargantuaUrl = 'https://extgargantua.perf.target.com'
const prodGargantuaUrl = 'https://extgargantua.target.com'

export const getGargantuaUrl = () => {
  const baseUrl = window.location.origin

  return apiConfig.environment !== ENVIRONMENT_PRODUCTION
    ? `${stageGargantuaUrl}?redirectUrl=${baseUrl}`
    : `${prodGargantuaUrl}?redirectUrl=${baseUrl}`
}
