import { startCase } from 'lodash/fp'

/**
 * Converts a given string to title case.
 * Title case means that the first letter of each word is capitalized.
 *
 * @param input - The string to be converted to title case. Defaults to an empty string.
 * @returns The converted string in title case.
 */
export const toTitleCase = (input: string = '') =>
  startCase(input.toLowerCase())

/**
 * Rounds a given number to two decimal places.
 *
 * @param number - The number to be rounded.
 * @returns The number rounded to two decimal places.
 */
export const roundToTwoDecimals = (number: number) =>
  number ? Math.round(number * 100) / 100 : null
