import RequiredContent from 'components/common/RequiredContent'
import { useSelector } from 'react-redux'
import { getSellerContacts } from 'store/selectors'

interface Props {
  sellerUserId?: string
}

export const DCContactContent = (props: Props) => {
  const { sellerUserId } = props

  const sellers = useSelector(getSellerContacts)

  const seller = sellers?.find((i) => i.id === sellerUserId)

  const emptyState = (
    <RequiredContent hasRequiredText={false} description="None provided." />
  )

  const phoneNumber = seller?.phone_numbers?.find(
    (num) => num.type === 'OFFICE',
  )

  return (
    <>
      {seller ? (
        <div className="hc-mb-sm hc-fs-sm">
          <div>
            {seller.first_name} {seller.last_name}
          </div>
          <div>
            +{phoneNumber?.country_code} {phoneNumber?.number}
          </div>
        </div>
      ) : (
        emptyState
      )}
    </>
  )
}

export default DCContactContent
