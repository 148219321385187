import { Grid, Heading, Placeholder } from '@enterprise-ui/canvas-ui-react'
import useReturnInsightsStore from 'v2/store/useReturnInsightsStore'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { roundToTwoDecimals } from 'v2/utils/helper'
import { useQuery } from '@tanstack/react-query'
import returnRateQuery from 'v2/query/returnRateQuery'

export type Props = {
  sellerId: string
  vmmId: string
}

export const ReturnInformation = ({ sellerId, vmmId }: Props) => {
  const { divisionId, categoryId, interval } = useReturnInsightsStore()

  const cardId = GREEN_FIELD_CARD_ID.TOTAL_RETURNED_ITEMS_CARD

  const { data, isError, isFetching } = useQuery(
    [
      'RETURN_RATE',
      {
        divisionId,
        categoryId,
        interval,
        sellerId,
        vmmId,
        cardId,
      },
    ],
    () =>
      returnRateQuery({
        divisionId,
        categoryId,
        interval,
        sellerId,
        vmmId,
        cardId,
      }),
    {
      enabled: !!divisionId || !!categoryId || !!interval || !!cardId,
    },
  )

  if (isFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="space-between" spacing="dense">
          <Placeholder.Text emphasized />
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <Grid.Container direction="column">
      <Grid.Item xs={12}>
        <Heading size={1}>
          {data[0]?.current_return_rate
            ? `${roundToTwoDecimals(data[0]?.current_return_rate)}%`
            : 'N/A'}
        </Heading>
        <p className="hc-clr-grey02 hc-fs-xs">Current Return Rate</p>
      </Grid.Item>
      <Grid.Item xs={12}>
        <Heading size={1}>{data[0]?.total_returned_items}</Heading>
        <p className="hc-clr-grey02 hc-fs-xs">Total Returned Items</p>
      </Grid.Item>
    </Grid.Container>
  )
}
