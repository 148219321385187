import { CONTENT_SERVICES, DIGITAL_MARKETING } from 'constants/partnerServices'
import { StyledServicesBlock } from './styles'
import { Grid } from '@mui/material'
import TitleBar from 'components/common/TitleBar'
import { PartnerService } from 'types/PartnerServices'
import ContentServicesCard from './Cards/ContentServicesCard'
import Header from './Header'
import { getServicesData } from './partnerServicesData'

export const PartnerServicesPage = () => {
  const servicesData = getServicesData()

  return (
    <>
      <Header />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleBar title={DIGITAL_MARKETING} />
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            data-testid="content-services"
          >
            {servicesData.map((service: PartnerService, index) => {
              if (service.category === DIGITAL_MARKETING) {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={6} key={index}>
                    <ContentServicesCard {...service} />
                  </Grid>
                )
              }
              return null
            })}
          </Grid>

          <StyledServicesBlock data-testid="content-services-block">
            <TitleBar title={CONTENT_SERVICES} />
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              data-testid="content-services"
            >
              {servicesData
                .slice(0, 4)
                .map((service: PartnerService, index) => {
                  if (service.category === CONTENT_SERVICES) {
                    return (
                      <Grid item xs={12} sm={12} md={12} lg={6} key={index}>
                        <ContentServicesCard {...service} />
                      </Grid>
                    )
                  }
                  return null
                })}
              {servicesData
                .slice(4, 7)
                .map((service: PartnerService, index) => {
                  if (service.category === CONTENT_SERVICES) {
                    return (
                      <Grid item xs={12} sm={12} md={12} lg={4} key={index}>
                        <ContentServicesCard {...service} />
                      </Grid>
                    )
                  }
                  return null
                })}
            </Grid>
          </StyledServicesBlock>
        </Grid>
      </Grid>
    </>
  )
}

export default PartnerServicesPage
