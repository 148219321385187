import { useReturnInsightsStore } from 'v2/store'
import { useQuery } from '@tanstack/react-query'
import returnRateQuery from 'v2/query/returnRateQuery'
import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import {
  CartesianGrid,
  Line,
  LineChart as RechartLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { formatValue } from 'v2/utils/formatters'

export type Props = {
  sellerId: string
  vmmId: string
  cardId: number
}

export const ReturnRateGraph = ({ sellerId, vmmId, cardId }: Props) => {
  const { divisionId, categoryId, interval } = useReturnInsightsStore()

  const { data, isError, isFetching } = useQuery(
    [
      'RETURN_RATE',
      {
        divisionId,
        categoryId,
        interval,
        sellerId,
        vmmId,
        cardId,
      },
    ],
    () =>
      returnRateQuery({
        divisionId,
        categoryId,
        interval,
        sellerId,
        vmmId,
        cardId,
      }),
    {
      enabled: !!divisionId || !!categoryId || !!interval || !!cardId,
    },
  )

  if (isFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="space-between" spacing="dense">
          <Placeholder.Rect emphasized />
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <ResponsiveContainer className="hc-pb-2x" width="100%" height={250}>
      <RechartLineChart data={data} margin={{ bottom: 20 }}>
        <CartesianGrid strokeDasharray="3, 3" />
        <YAxis />
        <XAxis
          dataKey="x_axis"
          angle={30}
          dy={14}
          tick={{ 'aria-hidden': true }}
        />
        <Tooltip
          labelFormatter={(date: string) => 'Order Date: ' + date}
          formatter={(value: any) => [
            `Return Rate: ${formatValue(value, 'percentNoCalc')}`,
          ]}
        />
        <Line
          type="monotone"
          dataKey="y_axis"
          stroke={'var(--canvas-font-color--interactive)'}
          strokeWidth={3}
        />
      </RechartLineChart>
    </ResponsiveContainer>
  )
}

export default ReturnRateGraph
