export type ValueFormats =
  | 'number'
  | 'currency'
  | 'percentage'
  | 'percentNoCalc'

export const formatValue = (value: number, format: ValueFormats) => {
  if (format === 'percentage') {
    return `${(value * 100).toFixed(2)}%`
  } else if (format === 'percentNoCalc') {
    return `${value.toFixed(2)}%`
  } else {
    return value
  }
}
