import {
  Button,
  Card,
  Divider,
  Grid,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { FC, useState, useEffect } from 'react'
import { StyledSubtitle } from '../styles'
import EnterpriseIcon, {
  ChartBarHorizontalIcon,
  DownloadIcon,
  MaximizeIcon,
} from '@enterprise-ui/icons'
import ReturnRateGraph from './ReturnRateGraph'
import { useSelector } from 'react-redux'
import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { ReturnInformation } from './ReturnInformation'
import TimelineSelector from './TimelineSelector'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'

export enum ViewType {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

const ReturnRate: FC = () => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const vmmId = useSelector(currentSellerVmmId) ?? ''

  const [cardId, setCardId] = useState(
    GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_MONTH,
  )

  const [view, setView] = useState<ViewType>(ViewType.MONTH)

  useEffect(() => {
    if (view === ViewType.MONTH)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_MONTH)
    else if (view === ViewType.WEEK)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_WEEK)
    else if (view === ViewType.DAY)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_DAY)
  }, [view])

  return (
    <div className="hc-pt-xl">
      <Card>
        <Grid.Container className="hc-pa-normal">
          <Grid.Item md={3} lg={6} xl={7}>
            <Heading size={4}>RETURN RATE</Heading>
            <StyledSubtitle>
              % return sales for the orders that were placed in the selected
              time period
            </StyledSubtitle>
          </Grid.Item>
          <Grid.Item md={9} lg={6} xl={5}>
            <Grid.Container justify="space-between" className="display-flex">
              <Grid.Item xs={6}>
                <Button
                  data-testid="return-rate-compare"
                  type="secondary"
                  size="normal"
                >
                  <EnterpriseIcon icon={ChartBarHorizontalIcon} size="sm" />
                  <div>Compare</div>
                </Button>
              </Grid.Item>
              <Grid.Item xs={3}>
                <Button
                  data-testid="return-rate-compare"
                  type="secondary"
                  size="normal"
                  variant="contained"
                >
                  <EnterpriseIcon icon={MaximizeIcon} size="sm" />
                </Button>
              </Grid.Item>
              <Grid.Item xs={3}>
                <Button
                  data-testid="return-rate-compare"
                  type="secondary"
                  size="normal"
                  variant="contained"
                >
                  <EnterpriseIcon icon={DownloadIcon} size="sm" />
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Divider />
        <Grid.Container align="center" className="hc-pa-normal">
          <Grid.Item sm={7} lg={4}>
            <ReturnInformation sellerId={sellerId} vmmId={vmmId} />
          </Grid.Item>
          <Grid.Item sm={5} lg={8}>
            <Grid.Container
              direction="column"
              align="flex-end"
              justify="flex-end"
            >
              <TimelineSelector view={view} setView={setView} />
              <Grid.Item xs={12}>
                <ReturnRateGraph
                  sellerId={sellerId}
                  vmmId={vmmId}
                  cardId={cardId}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </Card>
    </div>
  )
}

export default ReturnRate
