import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Grid, Heading, Button } from '@enterprise-ui/canvas-ui-react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { getMemberOf } from 'store/selectors'

import startCase from 'lodash/fp/startCase'
import {
  DATE_DISPLAY_FORMAT,
  DATE_FORMAT_MONTH_DAY_YEAR_TIME,
  formatDate,
} from 'services/dateService'

import { AllowedProductSubType, UpdatedPromotion } from 'types/Promotion'
import ProductTypes from './ProductTypes'
import {
  StyledFinalStatusContainer,
  StyledPromoDetailsGridContainer,
  StyledPartnerPromotionDetailsCard,
  StyledPromotionDetailsHeading,
  StyledFinalStatusNote,
} from '../styles'
import { isOneOfUserRoles } from 'services/authorization'
import { USER_ROLE_ADMIN, USER_ROLE_PROGRAM_MANAGER } from 'services/roles'
import { useDispatch } from 'react-redux'
import { closeDialog } from 'store/dialog/actionCreator'
import { updatePromotion } from 'services/promotions'
import { showNotification } from 'store/notification/reducer'

export const PartnerPromotionDetailsForm = ({
  promotion,
}: {
  promotion: UpdatedPromotion
}) => {
  const memberOf = useSelector(getMemberOf)

  const [promoDescription, setPromoDescription] = useState<string | undefined>(
    promotion?.promotion_description,
  )
  const [participationDeadline, setParticipationDeadline] = useState<
    Date | string
  >(formatDate(promotion.participation_end_date, DATE_DISPLAY_FORMAT) ?? '')
  const [promoNotes, setPromoNotes] = useState<string | undefined>(
    promotion?.promotion_notes,
  )

  const [itemTypeRestrictions, setItemTypeRestrictions] = useState<
    boolean | undefined
  >(promotion?.item_type_restrictions)

  const [categoryManagerApproval, setCategoryManagerApproval] = useState<
    boolean | undefined
  >(promotion?.category_manager_approval_status === 'APPROVED')

  const [ppcoeAgreement, setPpcoeAgreement] = useState<string | undefined>(
    promotion?.ppcoe_agreement_approval_status,
  )
  const [siteExperienceApproval, setSiteExperienceApproval] = useState<
    string | undefined
  >(promotion?.site_experience_approval_status)
  const [internalReviewDetails, setInternalReviewDetails] = useState<
    string | undefined
  >(promotion?.internal_review_notes)

  const [finalStatus, setFinalStatus] = useState<string>(promotion.status)
  const [allowedProductSubtypes, setAllowedProductSubtypes] = useState<
    AllowedProductSubType[] | undefined
  >(() => {
    return promotion?.allowed_product_sub_types?.map((productSubtype: any) => {
      return {
        id: productSubtype.sub_type_id,
        name: productSubtype.sub_type_name,
        isPreSelcted: true,
        slectedItemTypeId: productSubtype.allowed_item_types_ids.map(
          (itemTypeId: number) => itemTypeId.toString(),
        ),
      }
    })
  })

  const dispatch = useDispatch()

  const formatDiscount = (discountType: string, discountValue: number) => {
    if (!discountType) return
    if (discountType === 'PercentageOff') return `${discountValue}%`
    else if (discountType === 'FixedPrice' || discountType === 'DollarOff')
      return `$${discountValue}`
  }

  const hasAccessForSubmission = isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
  ])

  const closeCallback = () => dispatch(closeDialog())

  const isEmptyOrUndefined = (
    value: string | boolean | Date | undefined | AllowedProductSubType[],
  ) => value === '' || value === undefined

  const formValidation = () => {
    const hasEmptySelectedItemTypes = (
      subtypes: AllowedProductSubType[] | undefined,
    ) =>
      subtypes?.some(({ id, selectedItemTypes }: AllowedProductSubType) => {
        return (
          isEmptyOrUndefined(id) ||
          selectedItemTypes === undefined ||
          selectedItemTypes.length === 0
        )
      })

    if (
      isEmptyOrUndefined(promoDescription) ||
      isEmptyOrUndefined(participationDeadline) ||
      isEmptyOrUndefined(itemTypeRestrictions) ||
      (itemTypeRestrictions &&
        hasEmptySelectedItemTypes(allowedProductSubtypes)) ||
      !categoryManagerApproval ||
      isEmptyOrUndefined(ppcoeAgreement) ||
      isEmptyOrUndefined(siteExperienceApproval)
    ) {
      return true
    }

    return false
  }

  const handleSubmit = (isSaveInDrafClick?: boolean) => {
    let promotionPayload: any

    promotionPayload = {
      ...promotion,
      category_manager_approval_status: categoryManagerApproval
        ? 'APPROVED'
        : 'NOT_APPROVED',
      ...(ppcoeAgreement !== undefined && {
        ppcoe_agreement_approval_status: ppcoeAgreement,
      }),
      ...(siteExperienceApproval !== undefined && {
        site_experience_approval_status: siteExperienceApproval,
      }),
      ...(itemTypeRestrictions !== undefined && {
        item_type_restrictions: itemTypeRestrictions,
      }),
      ...(!isEmptyOrUndefined(allowedProductSubtypes) && {
        allowed_product_sub_types:
          itemTypeRestrictions === true
            ? allowedProductSubtypes
                ?.map((allowedProductSubtype: AllowedProductSubType) => {
                  return (
                    !isEmptyOrUndefined(allowedProductSubtype.id) && {
                      sub_type_id: allowedProductSubtype.id,
                      sub_type_name: allowedProductSubtype.name,
                      allowed_item_types_ids:
                        allowedProductSubtype?.selectedItemTypes
                          ?.map(({ id }: any) => {
                            return id !== 'selectAll' ? id : false
                          })
                          .filter(Boolean),
                    }
                  )
                })
                .filter(Boolean)
            : [],
      }),
      ...((promotion.promotion_description !== undefined ||
        !isEmptyOrUndefined(promoDescription)) && {
        promotion_description: promoDescription,
      }),
      ...((promotion.participation_end_date !== undefined ||
        !isEmptyOrUndefined(participationDeadline)) && {
        participation_end_date: isEmptyOrUndefined(participationDeadline)
          ? ''
          : new Date(participationDeadline).toISOString(),
      }),
      ...((promotion.promotion_notes !== undefined ||
        !isEmptyOrUndefined(promoNotes)) && { promotion_notes: promoNotes }),
      ...((promotion.internal_review_notes !== undefined ||
        !isEmptyOrUndefined(internalReviewDetails)) && {
        internal_review_notes: internalReviewDetails,
      }),
      status: isSaveInDrafClick ? promotion.status : finalStatus,
    }

    try {
      updatePromotion(promotionPayload).then(() => {
        closeCallback()
      })
    } catch (e) {
      dispatch(
        showNotification({
          isShown: true,
          message: 'Server error. Please retry.',
          autoClose: true,
        }),
      )
    }
  }

  return (
    <Form>
      <StyledPartnerPromotionDetailsCard>
        <StyledPromoDetailsGridContainer
          className="hc-pa-expanded"
          spacing="dense"
        >
          <Grid.Item xs={12}>
            <StyledPromotionDetailsHeading size={4}>
              Partner Promotion Details
            </StyledPromotionDetailsHeading>
          </Grid.Item>

          <Grid.Item xs={6}>
            <Form.Field
              label="Promo Description *"
              type="text"
              onChange={(event: any) => setPromoDescription(event.target.value)}
              placeholder="Enter Description"
              value={promoDescription}
            />
          </Grid.Item>
          <Grid.Item className="hc-mt-normal" xs={6}>
            <div>
              <strong>Discount: </strong>
            </div>
            <div data-testid="discount">
              {startCase(promotion?.details?.[0]?.discount_type)}{' '}
              {formatDiscount(
                promotion?.details?.[0]?.discount_type ?? '',
                promotion?.details[0]?.discount_value ?? 0,
              ) ?? 'None'}
            </div>
          </Grid.Item>
          <Grid.Item xs={6}>
            <DatePicker
              id="participation-deadline"
              disableDates={{
                after: promotion?.start_date,
              }}
              onUpdate={(_id: any, value: any) =>
                setParticipationDeadline(value)
              }
              placeholder="Select Date"
              label="Participation Deadline *"
              value={participationDeadline}
            />
          </Grid.Item>
          <Grid.Item className="hc-mt-normal" xs={6}>
            <div>
              <strong>Start Date: </strong>
              {formatDate(
                promotion?.start_date as any,
                DATE_FORMAT_MONTH_DAY_YEAR_TIME,
              )}
            </div>
            <div>
              <strong>End Date: </strong>
              {formatDate(
                promotion?.end_date as any,
                DATE_FORMAT_MONTH_DAY_YEAR_TIME,
              )}
            </div>
          </Grid.Item>

          <Grid.Item xs={12}>
            <Form.Field
              data-testid="promo-notes"
              label="Promo Notes"
              onChange={(event: any) => setPromoNotes(event.target.value)}
              placeholder="Enter Notes"
              type="textarea"
              value={promoNotes}
            />
          </Grid.Item>
          <Grid.Item xs={12}>
            <Form.Field
              data-testid="item-type-restrictions"
              type="radio"
              label="Item Type Restrictions *"
              onUpdate={(_id: any, value: boolean) =>
                setItemTypeRestrictions(value)
              }
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              value={itemTypeRestrictions}
            />
          </Grid.Item>
          {itemTypeRestrictions === true && (
            <Grid.Item xs={12}>
              <ProductTypes
                allowedProductSubtypes={allowedProductSubtypes}
                setAllowedProductSubtypes={setAllowedProductSubtypes}
              />
            </Grid.Item>
          )}
        </StyledPromoDetailsGridContainer>
      </StyledPartnerPromotionDetailsCard>
      <StyledPartnerPromotionDetailsCard className="hc-mt-normal">
        <StyledPromoDetailsGridContainer
          className="hc-pa-expanded"
          spacing="dense"
        >
          <Grid.Item xs={12}>
            <StyledPromotionDetailsHeading size={4}>
              Approval Process
            </StyledPromotionDetailsHeading>
          </Grid.Item>
          <Grid.Item xs={6}>
            <p>Category Management Approval *</p>
            <Form.Field
              checked={categoryManagerApproval}
              disabled={!hasAccessForSubmission}
              data-testid="category-manager-approval"
              onChange={() =>
                setCategoryManagerApproval(!categoryManagerApproval)
              }
              label="Approved"
              type="checkbox"
            />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Form.Field
              type="radio"
              label="PPCOE Agreement *"
              onUpdate={(_id: any, value: string) => setPpcoeAgreement(value)}
              options={[
                {
                  value: 'APPROVED',
                  label: 'Approved',
                  disabled: !hasAccessForSubmission,
                },
                {
                  value: 'NOT_REQUIRED',
                  label: 'Not Required',
                  disabled: !hasAccessForSubmission,
                },
              ]}
              value={ppcoeAgreement}
            />
          </Grid.Item>
          <Grid.Item xs={12}>
            <Form.Field
              type="radio"
              label="Site Experience Approval *"
              onUpdate={(_id: any, value: string) =>
                setSiteExperienceApproval(value)
              }
              options={[
                { value: 'APPROVED', label: 'Approved' },
                {
                  value: 'NOT_REQUIRED',
                  label: 'Not Required',
                },
              ]}
              value={siteExperienceApproval}
            />
          </Grid.Item>
          <Grid.Item xs={12}>
            <Form.Field
              data-testid="internal-review-details"
              onChange={(event: any) =>
                setInternalReviewDetails(event.target.value)
              }
              label="Internal Review Details"
              placeholder="Enter Text"
              type="textarea"
              value={internalReviewDetails}
            />
          </Grid.Item>
        </StyledPromoDetailsGridContainer>
      </StyledPartnerPromotionDetailsCard>
      <StyledFinalStatusContainer className="hc-mt-normal hc-pa-expanded">
        <Heading className="hc-mr-xl" size={4}>
          Final Status{' '}
        </Heading>
        <Form.Field
          className="hc-mt-normal"
          type="radio"
          onUpdate={(_id: any, value: string) => setFinalStatus(value)}
          options={[
            {
              value: 'PARTNER_VISIBLE',
              label: 'Visible to Partners',
              disabled: !hasAccessForSubmission,
            },
            {
              value: 'PARTNER_NOT_VISIBLE',
              label: 'Not Visible to Partners',
              disabled: !hasAccessForSubmission,
            },
          ]}
          value={finalStatus}
        />
        {!hasAccessForSubmission && (
          <StyledFinalStatusNote>
            Note: This section is configurable by CAT MAN Team.{' '}
          </StyledFinalStatusNote>
        )}
      </StyledFinalStatusContainer>

      <div className="display-flex hc-mt-normal">
        <Button
          className="hc-mr-dense"
          disabled={!hasAccessForSubmission || formValidation()}
          type="primary"
          onClick={() => handleSubmit(false)}
        >
          SUBMIT
        </Button>
        <Button
          className="hc-mr-dense"
          type="secondary"
          onClick={() => handleSubmit(true)}
        >
          SAVE IN DRAFT
        </Button>
        <Button type="secondary" onClick={closeCallback}>
          CANCEL
        </Button>
      </div>
    </Form>
  )
}

export default PartnerPromotionDetailsForm
