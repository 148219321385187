import { BoldText, CenterAlign, LostConnectionPopup } from './styles'
import { CircularProgress } from '@mui/material'
import { Button } from '@enterprise-ui/canvas-ui-react'

interface LostConnectionPopUpProps {
  shouldExpand: boolean
  retryConnection: () => void
}

export const LostConnectionPopUp = ({
  shouldExpand,
  retryConnection,
}: LostConnectionPopUpProps) => {
  const handleReloadPage = () => {
    window.location.reload()
  }

  const handleReconnectButton = () => {
    retryConnection()
  }

  return (
    <LostConnectionPopup expand={shouldExpand}>
      <CenterAlign className="display-flex hc-mb-normal">
        <CircularProgress />
      </CenterAlign>
      <BoldText className="hc-ta-center hc-fs-lg hc-mb-normal">
        Connection Lost
      </BoldText>
      <div className="hc-ta-center hc-mb-expanded">
        <p>
          Sorry, your connection has been lost. We’re attempting to reconnect.
        </p>
      </div>
      <CenterAlign className="display-flex">
        <Button
          className="hc-mr-dense"
          type="secondary"
          onClick={handleReloadPage}
        >
          Reload Page
        </Button>
        <Button type="primary" onClick={handleReconnectButton}>
          Reconnect
        </Button>
      </CenterAlign>
    </LostConnectionPopup>
  )
}

export default LostConnectionPopUp
