import { FC, useState, useEffect } from 'react'
import {
  Heading,
  Grid,
  Button,
  ButtonGroup,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'

import { useGreenFieldItemTypeAutoComplete } from 'v2/hooks/autoComplete'
import { useReturnInsightsStore } from 'v2/store'
import { StyledCardContainer, StyledItemTypeGridItem } from '../styles'
import TopSKUTypeTable from './TopSKUTypeTable'
import AddColumns from './AddColumns'

export enum ViewType {
  VC_VIEW = 'VC_VIEW',
  VAP_VIEW = 'VAP_VIEW',
}

const TopSKUType: FC = () => {
  const [view, setView] = useState<ViewType>(ViewType.VAP_VIEW)
  const { selectedItemTypOption, itemTypeOptions, onItemTypUpdate } =
    useGreenFieldItemTypeAutoComplete()
  const { updateitemTypeIds } = useReturnInsightsStore()

  useEffect(() => {
    const itemTypeIds = selectedItemTypOption
      ? selectedItemTypOption?.map(({ id }) => id)
      : []
    updateitemTypeIds(itemTypeIds)
  }, [updateitemTypeIds, selectedItemTypOption])

  return (
    <StyledCardContainer>
      <Grid.Container
        className="hc-pa-normal"
        align="center"
        justify="space-between"
      >
        <Grid.Item xs={5}>
          <Heading size={4}>TOP SKU TYPE</Heading>
          <p className="hc-clr-grey02 hc-fs-xs">
            Top 10 SKUs surpassing the benchmark
          </p>
        </Grid.Item>
        <Grid.Item xs={7}>
          <Grid.Container align="center" justify="flex-end">
            <StyledItemTypeGridItem xs={6}>
              <Autocomplete
                multiselect
                placeholder="Select Item type"
                options={itemTypeOptions}
                onUpdate={onItemTypUpdate}
              />
            </StyledItemTypeGridItem>
            <Grid.Item>
              <ButtonGroup>
                <Button
                  onClick={() => setView(ViewType.VC_VIEW)}
                  type={view === ViewType.VC_VIEW ? 'primary' : 'secondary'}
                >
                  VC View
                </Button>
                <Button
                  onClick={() => setView(ViewType.VAP_VIEW)}
                  type={view === ViewType.VAP_VIEW ? 'primary' : 'secondary'}
                >
                  VAP View
                </Button>
              </ButtonGroup>
            </Grid.Item>
            <Grid.Item>
              <AddColumns />
            </Grid.Item>
            <Grid.Item>
              <Button type="secondary">
                <EnterpriseIcon size="lg" icon={DownloadIcon} />
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
      <TopSKUTypeTable view={view} />
    </StyledCardContainer>
  )
}

export default TopSKUType
