import { ChangeEvent, memo, useState, useEffect } from 'react'
import {
  StyledContentSeparator,
  StyledInputLabel,
  StyledOutlinedInput,
} from '../styles'
import { Grid, Input } from '@enterprise-ui/canvas-ui-react'
import { PriceRangePayload } from '../types'
import { toNumber } from '@amcharts/amcharts4/.internal/core/utils/Type'

export interface Props {
  rangeItem: PriceRangePayload
  rowLength: number
  index: number
  handleOnChangeMaxPrice: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => void
  handleReferralFeeChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => void
  setError: (error: boolean) => void
}

export const PriceRangeInput = ({
  rangeItem,
  rowLength,
  index,
  handleOnChangeMaxPrice,
  handleReferralFeeChange,
  setError,
}: Props) => {
  const [priceError, setPriceError] = useState(false)
  const [referralFeeError, setReferralFeeError] = useState(false)

  useEffect(() => {
    if (toNumber(rangeItem.max_price) <= toNumber(rangeItem.min_price)) {
      setPriceError(true)
      setError(true)
    } else {
      setPriceError(false)
    }

    if (toNumber(rangeItem.referral_percentage) > 100) {
      setReferralFeeError(true)
      setError(true)
    } else {
      setReferralFeeError(false)
    }
  }, [rangeItem, setError])

  useEffect(() => {
    if (!priceError && !referralFeeError) setError(false)
  }, [priceError, referralFeeError, setError])

  return (
    <>
      <Grid.Container
        spacing="dense"
        key={index}
        data-testid="price-range-container"
      >
        <Grid.Item xs={4}>
          <StyledInputLabel className="hc-pl-min">Above</StyledInputLabel>
          <StyledOutlinedInput>
            <Input.Text
              id="min_price"
              data-testid="min-price"
              name="min_price"
              size="small"
              type="number"
              defaultValue={rangeItem.min_price}
              value={rangeItem.min_price?.toString()}
              placeholder="Enter Price in $"
              inputProps={{
                min: 0,
                max: 99,
                step: 1,
                maxLength: 2,
              }}
              disabled
            />
          </StyledOutlinedInput>
        </Grid.Item>
        <Grid.Item xs={4}>
          <StyledInputLabel required className="hc-pl-min">
            To (in $)
          </StyledInputLabel>
          <StyledOutlinedInput>
            <Input.Text
              error={priceError}
              id="max_price"
              data-testid="max-price"
              name="max_price"
              size="small"
              type="number"
              value={rangeItem.max_price?.toString() || ''}
              placeholder="Enter Price in $"
              disabled={rowLength === index}
              inputProps={{
                min: 0,
                max: 99,
                step: 1,
                maxLength: 2,
              }}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                handleOnChangeMaxPrice(e, index)
              }}
            />
          </StyledOutlinedInput>
        </Grid.Item>
        <Grid.Item xs={4}>
          <StyledInputLabel className="hc-pl-min" required>
            Referral Fee %
          </StyledInputLabel>
          <StyledOutlinedInput>
            <Input.Text
              error={referralFeeError}
              id="referral_percentage"
              data-testid="referral-percentage"
              name="referral_percentage"
              size="small"
              type="number"
              value={rangeItem.referral_percentage?.toString() ?? ''}
              placeholder="Enter Value in %"
              inputProps={{
                min: 0,
                max: 99,
                step: 1,
                maxLength: 2,
              }}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                handleReferralFeeChange(e, index)
              }}
            />
          </StyledOutlinedInput>
        </Grid.Item>
      </Grid.Container>
      <StyledContentSeparator />
    </>
  )
}

export default memo(PriceRangeInput)
