import { useSelector } from 'react-redux'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'

import CanvasDatePickerFilter from 'components/common/FilterBar/CanvasDatePickerFilter'
import SelectFilter from 'components/common/FilterBar/SelectFilter'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import { currentSeller } from 'store/selectors'
import { PromotionStatus } from 'types/Promotion'
import { Direction } from 'services/pageableHelper'
import { SearchParamActions } from 'components/common/FilterBar/useSearchParams'
import { SelectOption } from 'types/SelectOption'
import {
  getDateAddedByDays,
  getDateAddedByYears,
  getDateSubtractedByDays,
  getDateSubtractedByYears,
} from 'v2/utils/date'

type SearchParams = {
  page: number
  perPage: number
  direction: Direction
  orderBy: string
  division_id: string | undefined
  pyramid_id: string | undefined
  promotion_id: string | undefined
  promotion_status: string | undefined
  effective_start_date: string | undefined
  effective_end_date: string | undefined
  upstream_status: string | undefined
  review_milestone: string | undefined
}

interface PromotionFilterProps {
  searchParams: SearchParams
  searchParamActions: SearchParamActions<SearchParams>
  pyramidActions: SelectOption[]
}

const todaysDate = new Date()

const ReviewMilstoneOptions = [
  { name: 'Category Management', value: 'CATEGORY_MANAGEMENT' },
  { name: 'Site Experience', value: 'SITE_EXPERIENCE' },
  { name: 'Review Complete', value: 'REVIEW_COMPLETE' },
]

const quickSelectRanges = [
  'lastMonth',
  'thisMonth',
  {
    label: 'This week',
    startDate: startOfWeek(todaysDate, { weekStartsOn: 0 }),
    endDate: endOfWeek(todaysDate, { weekStartsOn: 0 }),
  },
  {
    endDate: getDateAddedByYears(todaysDate, 1),
    label: 'Future promos',
    startDate: getDateAddedByDays(todaysDate, 1),
  },
  {
    endDate: getDateAddedByDays(todaysDate, 30),
    label: 'Current promos',
    startDate: todaysDate,
  },
  {
    endDate: getDateSubtractedByDays(todaysDate, 1),
    label: 'Past promos',
    startDate: getDateSubtractedByYears(todaysDate, 1),
  },
]

const PromotionFilters = ({
  searchParams,
  searchParamActions,
  pyramidActions,
}: PromotionFilterProps) => {
  const seller = useSelector(currentSeller)

  return (
    <Grid.Container className="hc-pa-md">
      <Grid.Item xs={3}>
        <TypeaheadFilter
          label="Promotion ID"
          value={searchParams.promotion_id}
          searchParam="promotion_id"
          onChange={searchParamActions.updateSearchParam}
        />
      </Grid.Item>

      <Grid.Item xs={3}>
        <CanvasDatePickerFilter
          id="promo-timeframe"
          label="Promo Timeframe"
          startSearchParam="effective_start_date"
          endSearchParam="effective_end_date"
          onChange={searchParamActions.updateSearchParam}
          quickSelectRanges={quickSelectRanges}
          searchParams={searchParams}
        />
      </Grid.Item>

      {!seller && (
        <>
          <Grid.Item xs={3}>
            <SelectFilter
              label="Pyramid"
              placeholder="Group (Pyramid)"
              searchParam="pyramid_id"
              onChange={searchParamActions.updateSearchParam}
              value={searchParams.pyramid_id}
              data={pyramidActions}
            />
          </Grid.Item>
          <Grid.Item xs={3}>
            <TypeaheadFilter
              label="Division"
              value={searchParams.division_id}
              searchParam="division_id"
              onChange={searchParamActions.updateSearchParam}
            />
          </Grid.Item>
          <Grid.Item xs={3}>
            <SelectFilter
              label="Review Status"
              placeholder="Review Status"
              searchParam="review_milestone"
              onChange={searchParamActions.updateSearchParam}
              value={searchParams.review_milestone}
              data={ReviewMilstoneOptions}
            />
          </Grid.Item>

          <Grid.Item xs={3}>
            <SelectFilter
              label="Partner Visibility Status"
              placeholder="Partner Visibility Status"
              searchParam="promotion_status"
              onChange={searchParamActions.updateSearchParam}
              value={searchParams.promotion_status}
              data={[
                {
                  name: 'Partner Visible',
                  value: PromotionStatus.PARTNER_VISIBLE,
                },
                {
                  name: 'Partner Not Visible',
                  value: PromotionStatus.PARTNER_NOT_VISIBLE,
                },
              ]}
            />
          </Grid.Item>

          <Grid.Item xs={3}>
            <SelectFilter
              label="Promotion Status"
              placeholder="Show Deactivated Promotion"
              searchParam="upstream_status"
              onChange={searchParamActions.updateSearchParam}
              value={searchParams.upstream_status}
              data={[
                {
                  name: 'Deactivated',
                  value: 'deactivated',
                },
              ]}
            />
          </Grid.Item>
        </>
      )}
    </Grid.Container>
  )
}

export default PromotionFilters
