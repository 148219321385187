import { add, sub, isBefore } from 'date-fns'

/**
 * Adds the specified number of days to the given date.
 *
 * @param date - The original date to which days will be added.
 * @param days - The number of days to add to the given date.
 * @returns A new Date object representing the date after adding the specified number of days.
 */
export function getDateAddedByDays(date: Date, days: number): Date {
  return new Date(add(date, { days }))
}

/**
 * Subtracts a specified number of days from a given date.
 *
 * @param date - The original date from which days will be subtracted.
 * @param days - The number of days to subtract from the date.
 * @returns A new Date object representing the date after subtracting the specified number of days.
 */
export function getDateSubtractedByDays(date: Date, days: number): Date {
  return new Date(sub(date, { days }))
}

/**
 * Adds the specified number of years to the given date.
 *
 * @param date - The original date to which years will be added.
 * @param years - The number of years to add to the given date.
 * @returns A new Date object representing the date after adding the specified number of years.
 */
export function getDateAddedByYears(date: Date, years: number): Date {
  return new Date(add(date, { years }))
}

/**
 * Subtracts the specified number of years from the given date.
 *
 * @param date - The original date from which years will be subtracted.
 * @param years - The number of years to subtract from the given date.
 * @returns A new Date object representing the date after subtracting the specified number of years.
 */
export function getDateSubtractedByYears(date: Date, years: number): Date {
  return new Date(sub(date, { years }))
}

/**
 * Gets the fiscal year for a given date.
 * The fiscal year starts on February 1st. If the given date is before February 1st,
 * the function returns the previous year as the fiscal year.
 *
 * Target's fiscal year starts on February 1st and ends on January 31st of the following year.
 *
 * @param date - The date for which to determine the fiscal year.
 * @returns The fiscal year as a number.
 */
export const getTargetFiscalYear = (date: Date): number => {
  const year = date.getFullYear()

  const fiscalYearStart = new Date(year, 1, 1) // February 1 (month is 0-indexed)

  // If the current date is before February 1st, return the previous year as the fiscal year
  if (isBefore(date, fiscalYearStart)) {
    return year - 1
  }
  return year
}
