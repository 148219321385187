import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useBlocker } from 'react-router-dom'

import {
  Heading,
  useToaster,
  Grid,
  Dialog,
} from '@enterprise-ui/canvas-ui-react'
import ServicesCard from './ServicesCard'
import { StyledGridContainer, StyledSubmitButton } from './styles'
import { updateTgidConsent } from 'services/sellerConsents'
import { currentSellerId, isRoleExternalUserSelector } from 'store/selectors'
import { getSeller } from 'services/seller'
import { ProductEnhancementConsent } from 'types/Seller'
import { SkeletonLoader } from 'components/common/SkeletonLoader'

interface OptedServicesType {
  derivedAttributes?: boolean
  productTitle?: boolean
  highlights?: boolean
  description?: boolean
}
const ServicesChoices = () => {
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const makeToast = useToaster()

  const [productEnhancementConsent, setProductEnhancementConsent] = useState<
    ProductEnhancementConsent | undefined
  >()
  const [optedServices, setOptedServices] = useState<
    OptedServicesType | undefined
  >()
  const [isLoading, setIsLoading] = useState(true)

  const sellerId = useSelector(currentSellerId)

  useEffect(() => {
    if (sellerId) {
      const fetchData = async () => {
        const response = await getSeller(sellerId)
        if (response) {
          const { product_enhancement_consent } = response
          const { derived_attributes, gen_ai_consent } =
            product_enhancement_consent || {}
          const { product_title, highlights, description } =
            gen_ai_consent || {}
          setIsLoading(false)
          setProductEnhancementConsent(product_enhancement_consent)
          setOptedServices({
            derivedAttributes: derived_attributes,
            productTitle: product_title,
            highlights,
            description,
          })
        }
      }
      fetchData()
    }
  }, [sellerId])

  const areApiValuesAndStateValuesSame = useMemo(
    () =>
      productEnhancementConsent?.derived_attributes !==
        optedServices?.derivedAttributes ||
      productEnhancementConsent?.gen_ai_consent?.product_title !==
        optedServices?.productTitle ||
      productEnhancementConsent?.gen_ai_consent?.highlights !==
        optedServices?.highlights ||
      productEnhancementConsent?.gen_ai_consent?.description !==
        optedServices?.description,
    [optedServices, productEnhancementConsent],
  )

  const services = [
    {
      title: 'Derivable Attributes',
      content: 'Target will generate these attributes for you.',
      icon: 'https://target.scene7.com/is/image/Target/GUEST_6e027469-e2af-45de-a25f-67f4aef5d5f3',
      value: optedServices?.derivedAttributes,
      onChange: (event: any) => {
        setOptedServices({
          ...optedServices,
          derivedAttributes: event.target.checked,
        })
      },

      isActive: productEnhancementConsent?.derived_attributes,
    },
    {
      title: 'Enhanced Product Titles',
      content: 'Target will improve titles for better external search results.',
      icon: 'https://target.scene7.com/is/image/Target/GUEST_3e2718b2-23f6-4d83-8673-66251355b492',
      value: optedServices?.productTitle,
      onChange: (event: any) =>
        setOptedServices({
          ...optedServices,
          productTitle: event.target.checked,
        }),
      isActive: productEnhancementConsent?.gen_ai_consent?.product_title,
    },
    {
      title: 'Enhanced Product Highlights',
      content:
        'Target will improve product highlights for better visibility in Target Search and on product display pages.',
      icon: 'https://target.scene7.com/is/image/Target/GUEST_6fcbf124-f9b9-44d7-ab8a-9e40ee500367',
      value: optedServices?.highlights,
      onChange: (event: any) =>
        setOptedServices({
          ...optedServices,
          highlights: event.target.checked,
        }),
      isActive: productEnhancementConsent?.gen_ai_consent?.highlights,
    },
    {
      title: 'Enhanced Product Long Copy',
      content:
        'Target will improve product long copy for better visibility in Target Search and on on product display pages.',
      icon: 'https://target.scene7.com/is/image/Target/GUEST_7c04866c-10fd-41d1-92b0-fd15f74111d1',
      value: optedServices?.description,
      onChange: (event: any) =>
        setOptedServices({
          ...optedServices,
          description: event.target.checked,
        }),
      isActive: productEnhancementConsent?.gen_ai_consent?.description,
    },
  ]

  const onSubmitTGidConsent = () => {
    updateTgidConsent(sellerId, optedServices).then(async (response) => {
      if (response) {
        makeToast({
          type: 'success',
          heading: 'Submit Successfully',
          message: 'Your service request submitted successfully.',
        })

        setProductEnhancementConsent(response)

        setOptedServices({
          derivedAttributes: response?.derived_attributes,
          productTitle: response?.gen_ai_consent.product_title,
          highlights: response?.gen_ai_consent.highlights,
          description: response?.gen_ai_consent?.description,
        })
      }
    })
  }

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: any) =>
      areApiValuesAndStateValuesSame &&
      currentLocation.pathname !== nextLocation.pathname,
  )

  return (
    <div className="hc-bg-grey06 hc-pa-normal">
      <Heading className="hc-ta-center hc-mb-normal" size={4}>
        Choose from Services
      </Heading>

      <StyledGridContainer justify="space-between" className="hc-ph-expanded">
        {services.map((service, index) => (
          <Grid.Item key={index} xs={3}>
            {isLoading ? (
              <SkeletonLoader height="300px" />
            ) : (
              <ServicesCard
                title={service.title}
                content={service.content}
                icon={service.icon}
                value={service.value}
                onChange={service.onChange}
                isActive={service.isActive}
              />
            )}
          </Grid.Item>
        ))}
      </StyledGridContainer>
      {isExternalUser && !isLoading && (
        <Grid.Container justify="center">
          <Grid.Item>
            <StyledSubmitButton
              className="hc-mt-normal"
              disabled={!areApiValuesAndStateValuesSame}
              type="primary"
              onClick={onSubmitTGidConsent}
            >
              {productEnhancementConsent === undefined ? 'SUBMIT' : 'SAVE'}
            </StyledSubmitButton>
          </Grid.Item>
        </Grid.Container>
      )}

      <Dialog
        headingText="Do you wish to continue? "
        isVisible={blocker?.state === 'blocked'}
        bodyText="You have unsaved changes. Are you sure you want to leave this page without saving?"
        refuseButtonText="No"
        onRefuse={() => {
          blocker?.reset?.()
        }}
        approveButtonText="Yes"
        onApprove={() => {
          blocker?.proceed?.()
        }}
      />
    </div>
  )
}

export default ServicesChoices
