import { FC, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { Placeholder, Grid } from '@enterprise-ui/canvas-ui-react'

import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { useReturnInsightsStore } from 'v2/store'
import { topSkuTypeQuery } from 'v2/query'
import { StyledTable } from '../styles'

interface OwnProps {
  view: string
}

const TopSKUTypeTable: FC<OwnProps> = ({ view }) => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const vmmId = useSelector(currentSellerVmmId) ?? ''
  const {
    selectedTopSkuTypeColumns,
    divisionId,
    categoryId,
    itemTypeIds,
    interval,
  } = useReturnInsightsStore()
  const { upc, department, division } = selectedTopSkuTypeColumns

  const {
    data: rowData,
    isError,
    isFetching,
  } = useQuery(
    [
      'TOP_SKU_TYPE',
      { divisionId, categoryId, itemTypeIds, interval, sellerId, vmmId, view },
    ],
    () =>
      topSkuTypeQuery({
        divisionId,
        categoryId,
        itemTypeIds,
        interval,
        sellerId,
        vmmId,
        view,
      }),
    {
      enabled:
        !!divisionId ||
        !!categoryId ||
        !!itemTypeIds.length ||
        !!interval ||
        !!view,
    },
  )

  const columnDefs = useMemo(
    () =>
      [
        {
          field: 'tcin',
          headerName: 'TCIN',
        },
        {
          field: 'partnerSku',
          headerName: 'Partner SKU',
        },
        {
          field: 'itemName',
          headerName: 'Item Name',
        },
        {
          field: 'returnRate',
          headerName: 'Return Rate',
        },
        {
          field: 'benchmark',
          headerName: 'Benchmark',
        },
        {
          field: 'returnGmv',
          headerName: 'Return GMV',
        },
        {
          field: 'returnReasons',
          headerName: 'Return Reasons',
        },
        upc
          ? {
              field: 'upc',
              headerName: 'UPC',
            }
          : null,
        division
          ? {
              field: 'division',
              headerName: 'Division',
            }
          : null,
        department
          ? {
              field: 'department',
              headerName: 'Department',
            }
          : null,
      ].filter(Boolean),
    [upc, division, department],
  )

  if (isFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="space-between" spacing="dense">
          {Array.from({ length: 28 }).map((_, index) => (
            <Grid.Item key={index} xs={3}>
              <Placeholder.Rect height="30px" />
            </Grid.Item>
          ))}
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <>
      {rowData?.length ? (
        <StyledTable
          scrollableWidth
          alternateRowColor
          id="top-sku-type-table"
          cellPadding="dense"
          data={{
            columnDefs,
            rowData,
          }}
        />
      ) : (
        <div className="hc-pa-expanded hc-ta-center">
          <p>No results found!</p>
        </div>
      )}
    </>
  )
}

export default TopSKUTypeTable
