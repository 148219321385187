import { useEffect, useCallback, useState, useRef } from 'react'
import { logEvent, LogLevel } from '@praxis/component-logging'
import DOMPurify from 'dompurify'
import { useAuth } from '@praxis/component-auth'
import Typography from '@mui/material/Typography'

import { Button, Input } from '@enterprise-ui/canvas-ui-react'

import EnterpriseIcon, {
  BrandIcon,
  CancelIcon,
  MaximizeIcon,
  PaperAirplaneIcon,
  MydayIcon,
  MinimizeIcon,
} from '@enterprise-ui/icons'

import config from 'config/apiConfig'
import './chatbot.scss'
import {
  ChatBotContainer,
  StyledButton,
  StyledHeader,
  StyledLogo,
  StyledChatBotWindow,
  StyledToastMessage,
  StyledOptions,
} from './styles'
import { setPageData, trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'
import LostConnectionPopUp from './LostConnectionPopUp'
import InitialisingLoader from './InitialisingLoader'

const BUSINESS_ID = 'BID_0027_TP'

interface Props {
  setIsOpen: (isOpen: boolean) => void
}

interface Option {
  title: string
  type: string
  value: string
}
interface Messages {
  message: string
  type: string
  time: string
  options?: Option[]
}

export const Chatbot = ({ setIsOpen }: Props) => {
  const { session, isAuthenticated } = useAuth()
  const { userInfo } = session ?? {}
  const wsRef = useRef<WebSocket>()
  const chatBotWindowRef = useRef<HTMLDivElement>(null)
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [waitingToReconnect, setWaitingToReconnect] = useState<null | boolean>(
    null,
  )
  const [textMessage, setTextMessage] = useState('')
  const [messageList, setMessageList] = useState<Messages[]>([])
  const [shouldExpand, setShouldExpand] = useState(false)
  const [isInputDisabled, setIsInputDisabled] = useState(false)

  // These regex case-insensitive. so it will match all the formats.
  const mainMenuRegex = /Main Menu/i
  const goBackRegex = /Go Back/i
  const yesRegex = /Yes/i
  const noRegex = /No/i

  const getFormattedMessage = (message: string) => {
    const regex = /\[(.*?)\]\((.*?)\)/g
    return message?.replace(regex, '<a href="$2" target="_blank">$1</a>') ?? ''
  }

  const scrollToBottom = (ele: HTMLDivElement, ms: number) => {
    setTimeout(() => {
      ele.scrollTo({ left: 0, top: ele.scrollHeight, behavior: 'smooth' })
    }, ms)
  }

  const reorderOptions = (menuItems: Option[]) => {
    const removeMainMenuAndGoBack = menuItems.filter(
      (item) =>
        !mainMenuRegex.test(item.title) && !goBackRegex.test(item.title),
    )

    const reorderedOptions = [
      ...removeMainMenuAndGoBack,
      menuItems.find((item) => goBackRegex.test(item.title)),
      menuItems.find((item) => mainMenuRegex.test(item.title)),
    ].filter(Boolean)

    return reorderedOptions
  }

  const sanitizeInputText = (message: string = '') => {
    return DOMPurify.sanitize(message)
  }

  const getCurrentUserFullName = useCallback(
    () => `${userInfo?.firstName}.${userInfo?.lastName}`,
    [userInfo?.firstName, userInfo?.lastName],
  )

  const getWSRequestObject = useCallback(
    (action: string, message: string) => {
      const currentUser = getCurrentUserFullName()

      return {
        action,
        message,
        author: currentUser,
        alias: currentUser,
        email: session?.userInfo?.email,
        business_id: BUSINESS_ID,
        user_id: currentUser,
        client_info: { id: 'target.com' },
        user_type: 'guest',
        client_type: 'widget',
      }
    },
    [getCurrentUserFullName, session?.userInfo?.email],
  )

  const retryConnection = useCallback(() => {
    if (isAuthenticated()) {
      wsRef.current = new WebSocket(config.chatBotWebSocketUrl)

      wsRef.current.onopen = () => {
        setIsConnected(true)
        const requestObject = getWSRequestObject('message', '__ping__')
        wsRef.current?.send(JSON.stringify(requestObject))
      }

      wsRef.current.onclose = () => {
        setIsConnected(false)
        setWaitingToReconnect(true)
      }

      wsRef.current.onerror = (err) => {
        const loggingInfo = {
          message: 'Chatbot is down',
          error: {
            type: err.type,
          },
        }
        const loggingOptions = {
          level: LogLevel.Error,
        }
        logEvent(loggingInfo, loggingOptions)
      }
    } else {
      window.location.reload()
    }
  }, [getWSRequestObject, isAuthenticated])

  useEffect(() => {
    if (wsRef.current) {
      sendMessage('__INITIAL_TRIGGER__', 'initial_trigger_message')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsRef.current])

  useEffect(() => {
    chatBotWindowRef?.current && scrollToBottom(chatBotWindowRef.current, 0)

    const anchorElements = chatBotWindowRef.current?.querySelectorAll('a') ?? ''

    if (anchorElements) {
      anchorElements.forEach((ele) => {
        ele.addEventListener('click', (event) => {
          const { href, innerText } = event.target as HTMLAnchorElement
          setPageData('quick_chat')
          trackCustomEvent(FireflyEvent.QUICK_CHAT_HYPERLINK, innerText, href)
        })
      })
    }

    if (messageList.at(-1)?.options?.length) {
      setIsInputDisabled(true)
    } else {
      setIsInputDisabled(false)
    }
  }, [messageList])

  useEffect(() => {
    if (isAuthenticated() && session) {
      if (waitingToReconnect) {
        return
      }

      if (!wsRef.current) {
        wsRef.current = new WebSocket(config.chatBotWebSocketUrl)
      }

      wsRef.current.onopen = () => {
        setIsConnected(true)
        const requestObject = getWSRequestObject('message', '__ping__')

        wsRef.current?.send(JSON.stringify(requestObject))
      }

      wsRef.current.onclose = () => {
        setIsConnected(false)
        setWaitingToReconnect(true)
      }

      wsRef.current.onerror = (err) => {
        const loggingInfo = {
          message: 'Chatbot is down',
          error: {
            type: err.type,
          },
        }
        const loggingOptions = {
          level: LogLevel.Error,
        }
        logEvent(loggingInfo, loggingOptions)
      }
    }

    return () => {
      wsRef.current?.close()
      wsRef.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingToReconnect, session])

  const handleTextChange = (event: any) => {
    setTextMessage(event.target.value)
  }

  const sendMessage = (message: string, fireflyLocation: string) => {
    if (isAuthenticated()) {
      const currentUser = getCurrentUserFullName()
      const requestObject = getWSRequestObject(
        'message',
        sanitizeInputText(message),
      )

      wsRef.current?.send(JSON.stringify(requestObject))

      if (wsRef.current !== undefined) {
        wsRef.current.onmessage = (e) => {
          const { message, author, time, options } = JSON.parse(e.data) ?? {}
          if (message !== '__INITIAL_TRIGGER__' && message !== '__pong__') {
            setMessageList((prevState) => {
              return [
                ...prevState,
                {
                  time,
                  ...(options && { options: reorderOptions(options) }),
                  message: getFormattedMessage(message),
                  type: author === currentUser ? 'user' : 'agent',
                },
              ]
            })

            trackCustomEvent(
              author === currentUser
                ? FireflyEvent.QUICK_CHAT_QUESTION_SUBMIT
                : FireflyEvent.QUICK_CHAT_RESPONSE,
              author === currentUser ? 'question_submit' : 'answer',
              message,
              author === currentUser
                ? fireflyLocation
                : options
                ? options.map((option: any) => option.title).join(', ')
                : '',
            )
          }
          setPageData('quick_chat')
          setTextMessage('')
        }
      }
    } else {
      window.location.reload()
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && textMessage) {
      sendMessage(textMessage, 'search_bar')
    }
  }

  const handleButtonClick = (message: string, fireflyLocation: string) => {
    sendMessage(message, fireflyLocation)
  }

  const handleExpandButtonClick = () => {
    setShouldExpand((prevState) => !prevState)
  }

  return (
    <ChatBotContainer expand={shouldExpand} className="chatbot-window hc-mr-xl">
      <StyledHeader className="chatbot-window-header display-flex hc-clr-contrast hc-pt-md hc-pb-md">
        <div className="hc-ml-lg">
          <EnterpriseIcon size="lg" icon={BrandIcon} />
          <strong>
            + <span className="hc-ml-min">Quick Help </span>
          </strong>{' '}
          <span className="hc-fs-md hc-clr-contrast hc-ml-md"></span>
        </div>
        <div className="display-flex">
          <StyledButton
            aria-label={`${
              shouldExpand ? 'maximize' : 'minimize'
            } chatbot window`}
            iconOnly
            type="ghost"
            onClick={handleExpandButtonClick}
          >
            <EnterpriseIcon
              icon={shouldExpand ? MinimizeIcon : MaximizeIcon}
              fill="white"
            />
          </StyledButton>
          <div className="hc-mr-md">
            <StyledButton
              aria-label="close chatbot window"
              iconOnly
              type="ghost"
              onClick={() => setIsOpen(false)}
            >
              <EnterpriseIcon icon={CancelIcon} />
            </StyledButton>
          </div>
        </div>
      </StyledHeader>
      <div className="display-flex flex-column chatbot-window-content-container">
        {messageList.length ? (
          <>
            <StyledChatBotWindow
              diableScroll={!!(!isConnected && waitingToReconnect)}
              ref={chatBotWindowRef}
              className="chatbot-window-content"
            >
              <StyledToastMessage>
                <Typography>
                  Welcome to Quick Help! I'm here to answer your questions about
                  Target+.
                </Typography>
              </StyledToastMessage>
              {messageList.map((list, index) =>
                list.type === 'agent' ? (
                  <div key={index} className="display-flex hc-mt-md hc-mb-md">
                    <StyledLogo />
                    <div className="messages-width hc-mt-min">
                      <div className="received-message">
                        <Typography className="hc-pa-normal box arrow-left">
                          <span
                            dangerouslySetInnerHTML={{ __html: list.message }}
                          />
                        </Typography>
                      </div>
                      {index === messageList.length - 1 && (
                        <div className="hc-ml-md">
                          {list?.options?.map((option) => (
                            <StyledOptions
                              key={option.title}
                              isInlineBlock={
                                mainMenuRegex.test(option.title) ||
                                goBackRegex.test(option.title) ||
                                yesRegex.test(option.title) ||
                                noRegex.test(option.title)
                              }
                              isNavigationButton={
                                mainMenuRegex.test(option.title) ||
                                goBackRegex.test(option.title)
                              }
                            >
                              <Button
                                className="hc-mt-sm hc-mr-dense"
                                onClick={() =>
                                  handleButtonClick(option.title, 'options')
                                }
                              >
                                {option.title}
                              </Button>
                            </StyledOptions>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="display-flex hc-mt-md hc-mb-md row-reverse "
                  >
                    <EnterpriseIcon
                      className="profile-icon"
                      size="md"
                      icon={MydayIcon}
                    />
                    <div className="messages-width">
                      <div className="sent-message">
                        <Typography className="hc-pa-normal box-sent arrow-right">
                          <span
                            dangerouslySetInnerHTML={{ __html: list.message }}
                          />
                        </Typography>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </StyledChatBotWindow>
            <div className="chatbot-input-container display-flex hc-pb-lg hc-pt-md hc-pl-sm hc-pr-sm">
              <div>
                <Input.Text
                  onChange={handleTextChange}
                  placeholder={
                    isInputDisabled
                      ? 'Please select from the menu options'
                      : 'Please enter your question'
                  }
                  value={textMessage}
                  onKeyPress={handleKeyPress}
                  data-testid="message-input"
                  disabled={!messageList.length || isInputDisabled}
                />
              </div>
              <div className="hc-mr-dense hc-ml-min">
                <Button
                  aria-label="send chatbot message"
                  iconOnly
                  data-testid="send-chatbot-message"
                  disabled={!textMessage}
                  onClick={() => handleButtonClick(textMessage, 'search_bar')}
                  type="ghost"
                >
                  <EnterpriseIcon icon={PaperAirplaneIcon} />
                </Button>
              </div>
            </div>
          </>
        ) : (
          <InitialisingLoader />
        )}
      </div>
      {!isConnected && waitingToReconnect && (
        <LostConnectionPopUp
          shouldExpand={shouldExpand}
          retryConnection={retryConnection}
        />
      )}
    </ChatBotContainer>
  )
}

export default Chatbot
