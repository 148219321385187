import HeaderTitle from '../common/HeaderTitle'
import { Heading } from '@enterprise-ui/canvas-ui-react'
import ServicesChoices from './ServicesChoices'

const TGIDConsentPage = () => {
  return (
    <>
      <HeaderTitle title="Target Generated Item Data (TGID) Consent" />
      <Heading className="hc-mb-dense" size={5}>
        Target Generated Item Data
      </Heading>
      <p className="hc-mb-none">
        To accelerate item onboarding and boost product performance, Target has
        introduced a new program that uses cutting-edge generative Al models to
        create and enhance product attributes.
      </p>
      <p>
        You will receive the respective benefits for the service you choose from
        the below as your consent.
      </p>
      <ServicesChoices />
      <Heading className="hc-mb-dense hc-mt-expanded" size={5}>
        Key terms of consent
      </Heading>
      <ul>
        <li>
          All Items submitted for review will be processed as per your consent.
        </li>
        <li>
          Items enriched as part of this program will be flagged for your review
          on your original choice of item set up (Channel Partner or direct
          integration) with an expectation to be reviewed within 72 hours, post
          which items will be approved and launched on Target.com.
        </li>
        <li>
          Consent provided on this page will be extended to all item types
          authorized for you and for which the capability is available from
          Target's end.
        </li>
        <li>
          You can continue to use the regular item maintenance tools to manage
          your items and the changes will be reflected as per the current
          process.
        </li>
      </ul>
    </>
  )
}

export default TGIDConsentPage
