import { PriceRangePayload } from './types'

export const isEmptyOrNull = (value: string, idx: number) => {
  return (
    value === null ||
    value === '' ||
    value === undefined ||
    (value === '0' && idx !== 0)
  )
}

export const hasEmptyOrNullValues = (array: PriceRangePayload[]) =>
  array.some((obj) =>
    Object.values(obj).some((value, idx) => isEmptyOrNull(value, idx)),
  )

export const formatPercentage = (value: number) => {
  return (value * 100).toFixed(2).replace(/[.,]00$/, '')
}
