import StyledIcon from 'components/common/StyledIcon'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { formatDateMDYT } from 'components/common/EnhancedTable/formatters'
import TableSpacer from 'components/common/TableSpacer'

import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { StyledIconButton } from './styles'
import EnterpriseIcon, { PencilIcon } from '@enterprise-ui/icons'
import { TableHook } from 'components/common/EnhancedTable/useTable'
import { FlagName, flag } from 'flag'
import { formatPercentage } from 'components/ReferralFeesV2/helpers'

interface Props {
  table: TableHook
  data: ReferralBasePercentage[]
  total: number
  isPending: boolean
  canEdit?: boolean
  edit?: (element: ReferralBasePercentage) => (event: any) => void
  viewHistory?: (element: ReferralBasePercentage) => (event: any) => void
  handleOnEditClick?: (title: string, rowItem?: ReferralBasePercentage) => void
}

export const BaseReferralFeeTable = ({
  table,
  data,
  total,
  isPending,
  canEdit,
  edit,
  handleOnEditClick,
  viewHistory,
}: Props) => {
  const isPriceRangeEnabled = flag(FlagName.REFERRAL_FEE_WITH_PRICE_RANGE)

  const fieldList: EnhancedTableFieldType<ReferralBasePercentage>[] = [
    {
      key: 'subtypeName',
      heading: 'Subtype',
      hasSort: false,
      minWidth: 20,
    },
    {
      key: 'start_date',
      heading: 'Start Date',
      hasSort: true,
      minWidth: 40,
      formatCell: formatDateMDYT('start_date'),
    },
    {
      key: 'created_by',
      heading: 'Last Modified By',
      hasSort: true,
      minWidth: 40,
    },

    {
      key: 'referral_percentage',
      heading: 'Base Fee',
      hasSort: true,
      minWidth: 20,
      formatCell: (rowItem) => (
        <div>
          {rowItem.referral_percentage === 0
            ? 'NA'
            : `${formatPercentage(rowItem.referral_percentage)}%`}
        </div>
      ),
    },
  ]

  if (isPriceRangeEnabled) {
    fieldList.push({
      key: 'price_range_referral_percentages',
      heading: 'Price Range and Referral Fee',
      hasSort: true,
      minWidth: 20,
      formatCell: (rowItem) =>
        rowItem.price_range_referral_percentages
          ? rowItem.price_range_referral_percentages.map((item) => {
              const referral_percentage =
                item?.referral_percentage &&
                formatPercentage(Number(item?.referral_percentage))
              return (
                <div className="hc-pb-min">
                  ${item.min_price}-${item.max_price} : {referral_percentage}%
                </div>
              )
            })
          : 'NA',
    })
    if (canEdit) {
      fieldList.push({
        key: 'edit',
        heading: '',
        minWidth: 10,
        formatCell: (rowItem) => (
          <StyledIconButton
            data-testid="edit-base-referrel-fee-button"
            key="edit-base-referral-fees"
            aria-label="edit-base-referral-fees"
            onClick={() =>
              handleOnEditClick?.('Edit Base Referral Fees', rowItem)
            }
          >
            <EnterpriseIcon icon={PencilIcon} color="blue" />
          </StyledIconButton>
        ),
      })
    }
  }

  return (
    <>
      <TableSpacer>
        <EnhancedTable
          total={total}
          onChangePage={table.actions.changePage}
          onChangeRowsPerPage={table.actions.changePerPage}
          onRequestSort={table.actions.sort}
          orderBy={table.state.orderBy}
          order={table.state.direction}
          page={table.state.page}
          rowsPerPage={table.state.perPage}
          fieldList={fieldList}
          fieldListContext={{ handleOnEditClick, viewHistory }}
          paginationDisabled={false}
          data={data}
          isLoading={isPending}
          isUpdatedTable={isPriceRangeEnabled}
          actions={[
            {
              callback: edit,
              label: 'Edit',
              icon: <StyledIcon iconType="edit" />,
            },
            {
              callback: viewHistory,
              label: 'View History',
              icon: <StyledIcon iconType="history" />,
            },
          ]}
        />
      </TableSpacer>
    </>
  )
}

export default BaseReferralFeeTable
