import { useState, useRef, useEffect } from 'react'
import {
  Alert,
  Anchor,
  Button,
  Drawer,
  Grid,
  Input,
  ProgressBar,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { DrawerType } from './EnableTwoDay'
import { FileContainer, FileName, StyledItem, StyledSubtitle } from './styles'
import { XLXS_CONTENT_TYPE } from 'constants/fileTypes'
import { MEGABYTES_AS_BYTES_1 } from 'constants/sizeLimits'
import { useStateValue } from 'stateManager/StateProvider'
import EnterpriseIcon, {
  CancelCircleIcon,
  DownloadIcon,
  FileIcon,
} from '@enterprise-ui/icons'
import { TWO_DAY_ITEM, TWO_DAY_STATE } from './constants'
import {
  downloadReport,
  saveReportAsFile,
  submitReport,
} from 'services/reports'
import { ReportType } from 'types/Report'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from 'store/notification/reducer'
import { currentSellerId } from 'store/selectors'
import { saveFile } from 'services/files'

interface Props {
  drawerType: DrawerType
  isDrawerOpen: boolean
  onRequestClose: () => void
}

export const EnableTwoDayDrawer = ({
  drawerType,
  isDrawerOpen,
  onRequestClose,
}: Props) => {
  const { state, updateState } = useStateValue()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isPending, setIsPending] = useState<boolean>()
  const [hasCurrentData, setHasCurrentData] = useState(true)

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [state.file])

  const sellerId = useSelector(currentSellerId)

  const dispatch = useDispatch()

  const isItemUpload = drawerType.name === TWO_DAY_ITEM.name

  const showProcessingBar =
    state.inProgressReport &&
    state.inProgressReport.type === drawerType.reportType

  const showLoadingBar =
    state.currentDataReport && state.currentDataReport.type === drawerType.type

  const templateLink = isItemUpload
    ? TWO_DAY_ITEM.templateLink
    : TWO_DAY_STATE.templateLink
  const templateName = isItemUpload
    ? TWO_DAY_ITEM.templateName
    : TWO_DAY_STATE.templateName

  const onClearFileButtonClick = () => {
    updateState({ file: undefined })
  }

  const handleFileChange = (event: any) => {
    if (event.target.files[0]?.size <= MEGABYTES_AS_BYTES_1) {
      updateState({ file: event.target.files[0] })
      setErrorMessage('')
    } else {
      setErrorMessage('File size exceeds 1MB. Please reduce and retry.')
    }
  }

  const handleErrorReportClick = () => {
    const saveFileParams = {
      report: state.completedReport,
      title: 'Download Excel',
      hasDateRange: false,
    }
    saveReportAsFile(saveFileParams)
    updateState({ completedReport: undefined })
  }

  const handleFileUpload = async () => {
    if (isPending) {
      return
    }
    setIsPending(true)
    try {
      const savedReport = await submitReport({
        type: isItemUpload
          ? ReportType.BULK_UPDATE_TWO_DAY_SHIPPING_PRODUCTS
          : ReportType.BULK_UPDATE_TWO_DAY_SHIPPING_STATES,
        sellerId: sellerId,
        reportInput: state.file,
        reportName: state.fileName,
      })

      setErrorMessage('')
      if (savedReport) {
        updateState({ inProgressReport: savedReport })
        dispatch(
          showNotification({
            isShown: true,
            message: `Your upload is successful. The ${state.file.name} file has been uploaded. Please wait a few minutes while we process this file.`,
            severity: 'success',
            autoClose: true,
          }),
        )
        setIsPending(false)
        updateState({ file: undefined, fileName: '', isLoading: true })
      }
    } catch (e) {
      setIsPending(false)
      updateState({ file: undefined, fileName: '' })
      setErrorMessage('Server error. Please retry.')
      dispatch(
        showNotification({
          isShown: true,
          message: 'Server error. Please retry.',
          autoClose: true,
        }),
      )
    }
  }

  const handleDownload = async (link: string, name: string) => {
    const data = await downloadReport(link)
    saveFile({ data, name })
  }

  const handleCurrentDataClick = async () => {
    const data = await submitReport({
      type: isItemUpload ? TWO_DAY_ITEM.type : TWO_DAY_STATE.type,
      sellerId: sellerId,
    })
    if (data) {
      updateState({ isCurrentDataLoading: true, currentDataReport: data })
    } else {
      setHasCurrentData(false)
    }
  }

  return (
    <Drawer
      headingText={drawerType.title}
      isVisible={isDrawerOpen}
      onRequestClose={onRequestClose}
      spacing="expanded"
      xs={3}
      key="enable-two-day-drawer"
    >
      <Grid.Container justify="space-between" direction="column">
        <Grid.Item>
          <Grid.Container>
            <Grid.Item xs={8}>
              <StyledSubtitle className="hc-pt-min">
                {drawerType.subtitle}
              </StyledSubtitle>
            </Grid.Item>
          </Grid.Container>
          {state.file ? (
            <FileContainer className="hc-bg-contrast-weak hc-mt-normal hc-ta-center">
              <FileName>
                <div className="hc-fs-sm hc-pl-md hc-pt-min">
                  <EnterpriseIcon icon={FileIcon} />
                  {state.file?.name}
                </div>
                <Button
                  onClick={onClearFileButtonClick}
                  ref={inputRef}
                  type="ghost"
                >
                  <EnterpriseIcon
                    icon={CancelCircleIcon}
                    aria-label={`Delete file ${state.file?.name}`}
                  />
                </Button>
              </FileName>

              <div className="hc-fs-sm hc-pl-md hc-pt-min ">
                <StyledSubtitle>
                  <pre>{drawerType.dropText}</pre>
                </StyledSubtitle>
              </div>
            </FileContainer>
          ) : (
            <div className="hc-mt-lg">
              <Input.DropArea
                aria-label={drawerType.dropText}
                accept={`${XLXS_CONTENT_TYPE}`}
                fullwidth={true}
                onUpdate={handleFileChange}
                clickOnly
                dropText="Add file"
                instructionText={drawerType.dropText}
              />
            </div>
          )}
          <Grid.Container className="hc-pt-xl">
            <Grid.Item xs={6}>
              <Button
                type="ghost"
                className="hc-txt-uppercase"
                onClick={() => handleDownload(templateLink, templateName)}
              >
                Download Template
              </Button>
            </Grid.Item>
            <Grid.Item
              xs={6}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              {state.isCurrentDataLoading && showLoadingBar ? (
                <Spinner aria-label="Downloading current data" />
              ) : (
                <Button
                  type="secondary"
                  className="hc-txt-uppercase"
                  onClick={() => handleCurrentDataClick()}
                  disabled={!hasCurrentData}
                >
                  <EnterpriseIcon className="hc-pr-min" icon={DownloadIcon} />
                  Current Data
                </Button>
              )}
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        {errorMessage && (
          <div className="hc-fs-sm hc-mt-min hc-clr-error hc-bg-grey07">
            {errorMessage}
          </div>
        )}
        <div className="hc-pa-lg">
          {state.completedReport?.feedback?.map((feed: string) => (
            <Alert type="error" heading={feed} />
          ))}
          {state.completedReport?.download_url && (
            <Alert type="error">
              Click to view the{' '}
              <Anchor
                type="secondary"
                onClick={handleErrorReportClick}
                target="_blank"
                style={{ color: 'white' }}
              >
                Error Report
              </Anchor>
            </Alert>
          )}
          {!hasCurrentData && (
            <Alert
              type="error"
              heading="Current data is unavailable for this partner."
            />
          )}
        </div>

        {state.isLoading && showProcessingBar && (
          <div className="hc-pa-lg">
            Processing...
            <ProgressBar indeterminate aria-label="File is being processed" />
          </div>
        )}
        <StyledItem className="hc-pr-xl">
          <Button
            type="primary"
            className="hc-txt-uppercase"
            fullWidth
            disabled={!state.file}
            onClick={handleFileUpload}
          >
            Upload
          </Button>
        </StyledItem>
      </Grid.Container>
    </Drawer>
  )
}

export default EnableTwoDayDrawer
