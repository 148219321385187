import { FC } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'

import HeaderTitle from 'components/common/HeaderTitle'

import Filter from './Filter'
import ReturnRate from './ReturnRate'
import TopItemType from './TopItemType'
import TopSKUType from './TopSKUType'

const ReturnInsights: FC = () => {
  return (
    <>
      {/* We need to rewrite the HeaderTitle component using React Context. */}
      <HeaderTitle title="Return Performance Dashboard" />
      <Filter />
      <Grid.Container>
        <Grid.Item xs={6}>
          <ReturnRate />
        </Grid.Item>
        <Grid.Item xs={6}>
          <TopItemType />
        </Grid.Item>
        <Grid.Item xs={12}>
          <TopSKUType />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default ReturnInsights
