import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import isEqual from 'lodash/fp/isEqual'

import Grid from '@mui/material/Grid'

import EnhancedTable from 'components/common/EnhancedTable'
import FilterBar from 'components/common/FilterBar'
import DateRangeFilter from 'components/common/FilterBar/DateRangeFilter'
import MultiSelectFilter from 'components/common/FilterBar/MultiSelectFilter'
import { DialogEnum } from 'components/common/Dialog'
import TypeaheadFilter from 'components/common/FilterBar/TypeaheadFilter'
import {
  getEnhancedTablePageableProps,
  TableState,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'
import HeaderTitle from 'components/common/HeaderTitle'
import LabeledDataList from 'components/common/LabeledDataList'
import TableSpacer from 'components/common/TableSpacer'

import { DOWNLOAD_COUNT_LIMIT_TOOLTIP } from 'constants/sizeLimits'

import {
  formatDateRange,
  isoStringStartOfDay,
  isoStringEndOfDay,
  getDateBefore,
  DATE_DISPLAY_FORMAT,
  formatDate,
} from 'services/dateService'
import { getPrettyName } from 'services/enumerations'
import { formatLocaleNumber } from 'services/formatNumber'
import { getOrderList } from 'services/orders'
import { Direction } from 'services/pageableHelper'
import {
  submitReport,
  GenerateReportRequest,
  checkDownloadLimit,
} from 'services/reports'
import { scrollTo } from 'services/scrollTo'

import { currentSellerId, getEnumerationValues } from 'store/selectors'
import { openDialog } from 'store/dialog/actionCreator'

import StoreState from 'types/state'
import { Order, OrderStatus } from 'types/Orders'
import { EnumerationName } from 'types/Enumeration'
import { CollectionResponse } from 'types/Response'
import { ReportType } from 'types/Report'

import { getOrdersTableFieldList } from './getOrdersTableFieldList'
import UnshippedPastDue from './UnshippedPastDue'

const buildSearchParams = ({
  sellerId,
  orderPlacedStartDate,
  orderPlacedEndDate,
  requestedShipStartDate,
  requestedShipEndDate,
  orderStatus,
}: {
  sellerId: string | undefined
  orderPlacedStartDate: string | undefined
  orderPlacedEndDate: string | undefined
  requestedShipStartDate: string | undefined
  requestedShipEndDate: string | undefined
  orderStatus: string | string[] | undefined
}) => {
  const orderDateRange = formatDateRange(
    orderPlacedStartDate,
    orderPlacedEndDate,
  )

  const requestedShipDateRange = formatDateRange(
    requestedShipStartDate,
    requestedShipEndDate,
  )

  const orderDate = orderDateRange || undefined

  const requestedShipDate = requestedShipDateRange || undefined

  return {
    seller_id: sellerId,
    order_date: orderDate,
    requested_shipment_date: requestedShipDate,
    order_status: orderStatus,
  }
}

type SearchParams = TableState & {
  order_placed_start_date: string | undefined
  order_placed_end_date: string | undefined
  requested_ship_start_date: string | undefined
  requested_ship_end_date: string | undefined
  order_status: string | string[] | undefined
  seller_id: string | undefined
}

const initialSearchParams: SearchParams = {
  perPage: 100,
  page: 0,
  orderBy: 'order_date',
  direction: Direction.DESC,
  order_placed_start_date: undefined,
  order_placed_end_date: undefined,
  requested_ship_start_date: undefined,
  requested_ship_end_date: undefined,
  order_status: undefined,
  seller_id: undefined,
}

const unshippedOrderStatuses = [
  OrderStatus.PARTIALLY_SHIPPED,
  OrderStatus.ACKNOWLEDGED_BY_SELLER,
]

export const OrdersPage = () => {
  const dispatch = useDispatch()
  const statuses = useSelector((state: StoreState) =>
    getEnumerationValues(state, EnumerationName.ORDER_STATUS),
  )
  const sellerId = useSelector((state: StoreState) => currentSellerId(state))

  const [scroll, setScroll] = useState(false)
  const fieldList = getOrdersTableFieldList(!!sellerId)

  const [searchParams, searchParamActions, appliedFilterCount] =
    useSearchParams<SearchParams>(initialSearchParams)

  const [pending, setPending] = useState(false)
  const [orders, setOrders] = useState<Order[]>([])
  const [totalOrders, setTotalOrders] = useState(0)
  const [filteredOrders, setFilteredOrders] = useState<undefined | number>()

  const orderStatuses = statuses
    .map((status) => ({
      name: getPrettyName({
        enumeration: EnumerationName.ORDER_STATUS,
        value: status,
      }),
      value: status,
    }))
    .filter(
      (status) =>
        status.value !== OrderStatus.PENDING &&
        status.value !== OrderStatus.REFUNDED,
    )

  useEffect(() => {
    if (sellerId) {
      let mounted = true

      getOrderList(
        {
          page: initialSearchParams.page,
          perPage: initialSearchParams.perPage,
          orderBy: initialSearchParams.orderBy,
          direction: initialSearchParams.direction,
        },
        { seller_id: sellerId },
      ).then((response: CollectionResponse<Order>) => {
        if (mounted) {
          setTotalOrders(response.total)
        }
      })

      return () => {
        mounted = false
      }
    }
  }, [sellerId])

  const prevSearchParams = useRef(searchParams).current
  useEffect(() => {
    let mounted = true

    setPending(true)

    const sellerIdParam = sellerId ?? searchParams.seller_id

    const params = buildSearchParams({
      sellerId: sellerIdParam,
      orderPlacedStartDate: searchParams.order_placed_start_date,
      orderPlacedEndDate: searchParams.order_placed_end_date,
      requestedShipStartDate: searchParams.requested_ship_start_date,
      requestedShipEndDate: searchParams.requested_ship_end_date,
      orderStatus: searchParams.order_status,
    })

    getOrderList(
      {
        page: searchParams.page,
        perPage: searchParams.perPage,
        orderBy: searchParams.orderBy,
        direction: searchParams.direction,
      },
      params,
    ).then((response: CollectionResponse<Order>) => {
      if (mounted) {
        setPending(false)
        setOrders(response.data)
        setFilteredOrders(response.total)

        if (
          scroll ||
          !isEqual(searchParams.page, prevSearchParams.page) ||
          !isEqual(searchParams.perPage, prevSearchParams.perPage)
        ) {
          scrollTo('scroll-ref', -400)
          setScroll(false)
        }
      }
    })

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sellerId,
    searchParams.seller_id,
    searchParams.page,
    searchParams.perPage,
    searchParams.orderBy,
    searchParams.direction,
    searchParams.order_placed_start_date,
    searchParams.order_placed_end_date,
    searchParams.requested_ship_start_date,
    searchParams.requested_ship_end_date,
    searchParams.order_status,
  ])

  const handleCreateReport =
    ({ type }: GenerateReportRequest) =>
    () => {
      const requestedShipStartDate = searchParams.requested_ship_start_date
        ? isoStringStartOfDay(searchParams.requested_ship_start_date)
        : undefined

      const requestedShipEndDate = searchParams.requested_ship_end_date
        ? isoStringEndOfDay(searchParams.requested_ship_end_date)
        : undefined

      const orderPlacedStartDate = searchParams.order_placed_start_date
        ? isoStringStartOfDay(searchParams.order_placed_start_date)
        : undefined

      const orderPlacedEndDate = searchParams.order_placed_end_date
        ? isoStringEndOfDay(searchParams.order_placed_end_date)
        : undefined

      let orderStatus

      if (searchParams.order_status) {
        if (Array.isArray(searchParams.order_status)) {
          orderStatus = searchParams.order_status
        } else {
          orderStatus = [searchParams.order_status]
        }
      }

      return submitReport({
        type,
        sellerId: searchParams.seller_id ?? sellerId,
        startDate: orderPlacedStartDate,
        endDate: orderPlacedEndDate,
        parameters: {
          requested_shipment_date_start: requestedShipStartDate,
          requested_shipment_date_end: requestedShipEndDate,
          order_status: orderStatus as OrderStatus[],
        },
      })
    }

  const handleDownload = () => {
    const reportType = sellerId ? ReportType.ORDERS : ReportType.ORDERS_INTERNAL
    dispatch(
      openDialog({
        dialogEnum: DialogEnum.REPORT_DOWNLOAD_DIALOG,
        componentProps: {
          title: 'GENERATING REPORT...PLEASE WAIT',
          reportTitle: 'Orders',
          sellerId: sellerId ?? undefined,
          createReport: handleCreateReport({ type: reportType }),
        },
      }),
    )
  }

  const handleFilterClear = () => {
    searchParamActions.updateSearchParam(initialSearchParams)
  }

  const handleFilterBySellersUnshippedOrders = (sellerId: string) => () => {
    const date = getDateBefore(new Date(), 1, 'days')

    const formattedDate = formatDate(date, DATE_DISPLAY_FORMAT)

    setScroll(true)

    searchParamActions.updateSearchParam({
      ...initialSearchParams,
      seller_id: sellerId,
      order_status: unshippedOrderStatuses,
      requested_ship_end_date: formattedDate,
    })
  }

  const title = sellerId ? 'All Orders' : 'All Partner Orders'

  return (
    <div>
      <HeaderTitle title={title} />
      {!sellerId && (
        <UnshippedPastDue
          unshippedStatuses={unshippedOrderStatuses}
          onFilterBySellersUnshippedOrders={
            handleFilterBySellersUnshippedOrders
          }
        />
      )}
      {sellerId && (
        <LabeledDataList
          data={[
            { label: 'Total Orders:', data: formatLocaleNumber(totalOrders) },
          ]}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {orderStatuses.length > 0 && (
            <FilterBar
              count={filteredOrders}
              onClear={handleFilterClear}
              onDownload={handleDownload}
              appliedFilterCount={appliedFilterCount}
              disableDownload={checkDownloadLimit(filteredOrders)}
              downloadTooltip={DOWNLOAD_COUNT_LIMIT_TOOLTIP}
            >
              {!sellerId && (
                <Grid item xs={12} lg={6} data-testid="partner-typeahead">
                  <TypeaheadFilter
                    label={'Partner'}
                    value={searchParams.seller_id}
                    searchParam="seller_id"
                    onChange={searchParamActions.updateSearchParam}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={6}>
                <MultiSelectFilter
                  label="Order Status"
                  placeholder="Order Status"
                  searchParam="order_status"
                  value={searchParams.order_status}
                  data={orderStatuses}
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DateRangeFilter
                  label="Order Placed Date Range"
                  startValue={searchParams.order_placed_start_date}
                  startSearchParam="order_placed_start_date"
                  endValue={searchParams.order_placed_end_date}
                  endSearchParam="order_placed_end_date"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DateRangeFilter
                  label="Requested Ship Date Range"
                  startValue={searchParams.requested_ship_start_date}
                  startSearchParam="requested_ship_start_date"
                  endValue={searchParams.requested_ship_end_date}
                  endSearchParam="requested_ship_end_date"
                  onChange={searchParamActions.updateSearchParam}
                />
              </Grid>
            </FilterBar>
          )}
          <TableSpacer>
            <div id="scroll-ref">
              <EnhancedTable
                data-testid="table"
                data={orders}
                fieldList={fieldList}
                isLoading={pending}
                {...getEnhancedTablePageableProps(
                  searchParams,
                  searchParamActions,
                )}
                total={filteredOrders}
              />
            </div>
          </TableSpacer>
        </Grid>
      </Grid>
    </div>
  )
}

export default OrdersPage
