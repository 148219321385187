import styled from '@emotion/styled'
import { IconButton } from '@mui/material'
import { primary, white } from 'config/themeConfig'

export const StyledIconButton = styled(IconButton)(() => ({
  fontSize: '1rem',
  '& :hover': {
    backgroundColor: primary.main,
    borderRadius: '4px',
    '& svg': {
      fill: white.main,
    },
  },
}))

export const StyledText = styled.div`
  font-size: 14px;
  font-weight: 500;
`
