import axios from 'axios'
import uniq from 'lodash/fp/uniq'

import apiConfig from 'config/apiConfig'

import { getPageable, PagingParams } from './pageableHelper'
import { getNodesById } from './itemTaxonomies'

import Attribute from 'types/Attribute'
import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { CollectionResponse } from 'types/Response'

export function saveReferralBasePercentage(
  referralBasePercentage: ReferralBasePercentage,
): Promise<ReferralBasePercentage> {
  return axios
    .post(`${apiConfig.sms}/referral_base_percentages`, referralBasePercentage)
    .then((resp) => resp.data)
}

export async function getReferralBasePercentages(
  pagingParams: PagingParams,
  latest: boolean = true,
  subTypeId?: string,
): Promise<CollectionResponse<ReferralBasePercentage>> {
  const searchParams = {
    latest,
    sub_type_id: subTypeId,
  }

  if (subTypeId === '') {
    delete searchParams.sub_type_id
  }

  const params = {
    ...getPageable(pagingParams),
    ...searchParams,
  }
  params.sort = params.sort || 'created(desc)'
  const response = await axios
    .get(`${apiConfig.sms}/referral_base_percentages`, { params })
    .then((resp): CollectionResponse<ReferralBasePercentage> => {
      const { data, headers } = resp

      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        total,
        data,
      }
    })

  if (!response.data) {
    return response
  }

  const subTypeIds = subTypeId
    ? [subTypeId]
    : response.data.map((item: ReferralBasePercentage) => item.sub_type_id)
  const nodes = await getNodesById(uniq(subTypeIds))
  response.data.forEach((basePercentage: ReferralBasePercentage) => {
    const node = nodes.find(
      (n: Attribute) => n.id === basePercentage.sub_type_id,
    )
    basePercentage.subtypeName = node ? node.name : 'Unknown'
  })

  return response
}
