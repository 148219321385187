import styled from '@emotion/styled'
import { Divider, InputLabel } from '@enterprise-ui/canvas-ui-react'

export const StyledOutlinedInput = styled.div`
  .C-Input {
    border: 1px solid #0000001f;
    border-radius: 2px;
    font-size: 14px;
    height: 38px;
  }
`

export const StyledInputLabel = styled(InputLabel)({
  paddingBottom: '4px',
})

export const StyledContentSeparator = styled.div`
  height: 14px;
`

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(3),
}))
