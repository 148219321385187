import { Button, ButtonGroup, Grid } from '@enterprise-ui/canvas-ui-react'
import { ViewType } from '.'

export interface Props {
  view: ViewType
  setView: (viewType: ViewType) => void
}

export const TimelineSelector = ({ view, setView }: Props) => {
  return (
    <Grid.Item>
      <ButtonGroup>
        <Button
          onClick={() => setView(ViewType.MONTH)}
          type={view === ViewType.MONTH ? 'primary' : 'secondary'}
        >
          Month
        </Button>
        <Button
          onClick={() => setView(ViewType.WEEK)}
          type={view === ViewType.WEEK ? 'primary' : 'secondary'}
        >
          Week
        </Button>
        <Button
          onClick={() => setView(ViewType.DAY)}
          type={view === ViewType.DAY ? 'primary' : 'secondary'}
        >
          Day
        </Button>
      </ButtonGroup>
    </Grid.Item>
  )
}

export default TimelineSelector
