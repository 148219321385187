import { ChangeEvent, useState, useEffect } from 'react'
import { Button, Drawer, Grid } from '@enterprise-ui/canvas-ui-react'
import { TaxonomyTypeahead } from 'components/common/Typeahead'
import Attribute, { ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'
import {
  StyledContentSeparator,
  StyledInputLabel,
  StyledOutlinedInput,
} from './styles'
import { Input } from '@enterprise-ui/canvas-ui-react'
import PriceRangeSection from './PriceRange/PriceRangeSection'
import { PriceRangePayload } from './types'
import {
  buildReferralPercentage,
  updateReferralPercentages,
} from 'services/referralFees'
import { saveReferralBasePercentage } from 'services/referralBasePercentages'
import { ReferralBasePercentage } from 'types/BaseReferralFee'
import { hasEmptyOrNullValues } from './helpers'

export interface Props {
  attribute: Nullable<Attribute>
  percent: string
  sellerId?: string
  isBaseReferralFee: boolean
  isEditFlyoutOpen: boolean
  priceRanges: PriceRangePayload[]
  handleCloseEditFlyout: () => void
  onRequestChange: ({
    type,
  }: {
    type: 'attribute'
  }) => (value: Nullable<Attribute | string>) => void
  title: string
  handleReferralFeeChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export const EditReferralFeesDrawer = ({
  attribute,
  percent,
  sellerId,
  isBaseReferralFee,
  isEditFlyoutOpen,
  handleCloseEditFlyout,
  onRequestChange,
  handleReferralFeeChange,
  priceRanges,
  title,
}: Props) => {
  const [showPriceRange, setShowPriceRange] = useState(false)
  const [priceRangeRows, setPriceRangeRows] = useState<PriceRangePayload[]>([
    { min_price: '0', max_price: '', referral_percentage: '' },
    { min_price: '', max_price: '1000000', referral_percentage: '' },
  ])

  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const [error, setError] = useState(false)

  useEffect(() => {
    setIsSaveDisabled(
      !attribute ||
        (!showPriceRange && percent.length === 0) ||
        (showPriceRange && hasEmptyOrNullValues(priceRangeRows)) ||
        error,
    )
  }, [attribute, showPriceRange, percent, priceRangeRows, error])

  useEffect(() => {
    if (priceRanges.length > 0) {
      setPriceRangeRows(priceRanges)
      setShowPriceRange(true)
    }
  }, [priceRanges])

  const handleToggleChange = () => {
    setShowPriceRange(!showPriceRange)
  }

  const handleSubmit = async () => {
    try {
      const priceRangeRowsCopy = priceRangeRows
      if (priceRangeRows.length > 0 && showPriceRange) {
        priceRangeRowsCopy.forEach((item) => {
          item.referral_percentage =
            item.referral_percentage &&
            (Number(item.referral_percentage) / 100).toString()
        })
      }

      if (isBaseReferralFee) {
        const data: ReferralBasePercentage = {
          sub_type_id: attribute!.id,
          referral_percentage: percent ? parseInt(percent, 10) / 100 : 0,
        }
        if (priceRangeRows.length > 0 && showPriceRange)
          data.price_range_referral_percentages = priceRangeRowsCopy

        await saveReferralBasePercentage(data)
      } else {
        const data = buildReferralPercentage({
          itemTypeId: attribute!.id,
          percent: percent ? percent : '0',
        })

        if (priceRangeRows.length > 0 && showPriceRange)
          data.price_range_referral_percentages = priceRangeRowsCopy

        await updateReferralPercentages(sellerId ?? '', data)
      }

      handleCloseEditFlyout()
    } catch (e) {
      console.error(`Submit Failed:: ${e}`)
    }
  }

  return (
    <Drawer
      headingText={title}
      isVisible={isEditFlyoutOpen}
      onRequestClose={handleCloseEditFlyout}
      xs={12}
      sm={12}
      lg={5}
    >
      <section className="hc-bg-grey07 hc-pa-normal">
        <Grid.Container spacing="normal" className="hc-pt-normal">
          <Grid.Item xs={8}>
            <StyledInputLabel sx={{ paddingBottom: '6px' }} required>
              {isBaseReferralFee ? 'Subtype' : 'Item Type'}
            </StyledInputLabel>
            <TaxonomyTypeahead
              placeholder={`Select ${
                isBaseReferralFee ? 'Subtype' : 'Item Type'
              }`}
              searchType={isBaseReferralFee ? ITEM_SUBTYPE : ITEM_TYPE}
              onChange={onRequestChange({ type: 'attribute' })}
              value={attribute}
              name="attribute"
            />
          </Grid.Item>
          {!showPriceRange && (
            <Grid.Item xs={4}>
              <StyledInputLabel sx={{ paddingBottom: '6px' }} required>
                Referral Fee %
              </StyledInputLabel>
              <StyledOutlinedInput>
                <Input.Text
                  id="percent"
                  data-testid="percent"
                  name="percent"
                  size="small"
                  type="number"
                  defaultValue={percent}
                  onChange={(
                    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                  ) => handleReferralFeeChange(e)}
                  placeholder="Enter Value in %"
                  inputProps={{
                    min: 0,
                    max: 99,
                    step: 1,
                    maxLength: 2,
                  }}
                  disabled={showPriceRange}
                />
              </StyledOutlinedInput>
            </Grid.Item>
          )}
        </Grid.Container>
        <StyledContentSeparator />
        {attribute && (
          <Grid.Container>
            <Grid.Item>
              <Input.Toggle
                data-testid="show-price-range-toggle"
                aria-label="Set Price Range"
                value={showPriceRange}
                onChange={handleToggleChange}
              />
            </Grid.Item>
            <StyledInputLabel className="hc-pt-lg">
              Set Price Range
            </StyledInputLabel>
          </Grid.Container>
        )}
        <StyledContentSeparator />
        {showPriceRange && attribute && (
          <PriceRangeSection
            setError={setError}
            priceRangeRows={priceRangeRows}
            setPriceRangeRows={setPriceRangeRows}
          />
        )}
      </section>
      <Grid.Container
        className="hc-pa-normal"
        justify="flex-end"
        align="flex-end"
      >
        <Grid.Item xs={3}>
          <Button
            type="secondary"
            fullWidth
            data-testid="cancel"
            variant="contained"
            onClick={handleCloseEditFlyout}
          >
            Cancel
          </Button>
        </Grid.Item>
        <Grid.Item xs={3}>
          <Button
            variant="contained"
            type="primary"
            fullWidth
            data-testid="submit"
            onClick={handleSubmit}
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </Grid.Item>
      </Grid.Container>
    </Drawer>
  )
}

export default EditReferralFeesDrawer
