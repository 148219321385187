import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { ProductEnhancementConsent } from 'types/Seller'

interface consentPayloadType {
  derivedAttributes?: boolean
  productTitle?: boolean
  highlights?: boolean
  description?: boolean
}

export const updateTgidConsent = async (
  sellerId: string | undefined,
  consentPayload?: consentPayloadType,
) => {
  const payload: ProductEnhancementConsent = {
    derived_attributes: consentPayload?.derivedAttributes,
    gen_ai_consent: {
      product_title: consentPayload?.productTitle,
      highlights: consentPayload?.highlights,
      description: consentPayload?.description,
    },
  }

  const response = await axios.put(
    `${apiConfig.sms}/sellers/${sellerId}/consents`,
    payload,
  )
  return response.data
}
