function formatCurrency(
  input: Nullable<number> = 0,
  currency: string = 'USD',
): string {
  // credit https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-dollars-currency-string-in-javascript
  // format https://www.thefinancials.com/Default.aspx?SubSectionID=curformat

  const acceptedCodes = ['GBP', 'JPY', 'USD']
  let newInput = ''

  // Force currency to be an accepted value. Default to USD.
  if (currency.length === 0 || acceptedCodes.indexOf(currency) < 0) {
    currency = 'USD'
  }

  if (input !== null && !isNaN(input)) {
    if (currency === 'USD') {
      newInput =
        '$' +
        Number(input)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else if (currency === 'GBP') {
      newInput =
        '£' +
        Number(input)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else if (currency === 'JPY') {
      newInput =
        '¥' +
        Number(input)
          .toFixed(0)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    return newInput
  } else {
    return 'N/A'
  }
}

export default formatCurrency
