import { Tooltip } from '@enterprise-ui/canvas-ui-react'

import { toTitleCase, roundToTwoDecimals } from 'v2/utils/helper'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { postSellerCardExecution } from 'v2/services'
import { TopSkuTypes } from 'v2/types/topSkuType'

const getReturnReason = (returnReasons: any) => {
  if (!returnReasons?.length) {
    return
  }

  const allReasons = returnReasons
    .map((reason: any) => toTitleCase(reason?.return_reason))
    .join(', ')

  const toolTipText = returnReasons
    .map(
      (reason: any) =>
        `${toTitleCase(reason?.return_reason)} - ${reason?.return_percentage}%`,
    )
    .join(', ')

  return (
    <Tooltip location="top" content={toolTipText}>
      <p>{allReasons}</p>
    </Tooltip>
  )
}

const topSkuTypeQuery = async ({
  divisionId,
  categoryId,
  itemTypeIds,
  interval,
  sellerId,
  vmmId,
  view,
}: {
  divisionId: string | null
  categoryId: string | null
  itemTypeIds: string[]
  interval: string
  sellerId: string
  vmmId: string
  view: string
}) => {
  const config = {
    sellerId,
    cardId:
      view === 'VAP_VIEW'
        ? GREEN_FIELD_CARD_ID.TOP_SKU_TYPE_VAP_VIEW
        : GREEN_FIELD_CARD_ID.TOP_SKU_TYPE_VC_VIEW,
    payload: {
      filters: [
        {
          dimension: 'vendor_id',
          pattern: String(vmmId),
          type: 'in',
        },
        ...(itemTypeIds.length
          ? [
              {
                dimension: 'item_type',
                pattern: itemTypeIds,
                type: 'in',
              },
            ]
          : []),
        ...(divisionId
          ? [
              {
                dimension: 'division_id',
                pattern: divisionId,
                type: 'in',
              },
            ]
          : []),
        ...(categoryId
          ? [
              {
                dimension: 'group_id',
                pattern: categoryId,
                type: 'in',
              },
            ]
          : []),
      ],
      time_period: {
        interval,
        calendar_type: 'Fiscal',
        type: 'relative',
      },
    },
  }

  const data = await postSellerCardExecution(config)

  const mappedData: TopSkuTypes[] = data.map(
    (item: any): TopSkuTypes => ({
      tcin: item.tcin,
      partnerSku: item.partner_sku,
      itemName: item.item_type,
      returnRate: `${roundToTwoDecimals(item.return_rate)}%`,
      benchmark: `${roundToTwoDecimals(item.benchmark)}%`,
      returnGmv: `$${roundToTwoDecimals(item.return_gmv)}`,
      upc: item.upc,
      division: `${toTitleCase(item.division_name)}`,
      department: `${toTitleCase(item.department_name)}`,
      returnReasons: {
        cellDisplay: getReturnReason(item.top_three_return_reasons),
      },
    }),
  )

  return mappedData
}

export default topSkuTypeQuery
