import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'

export const HeadingBorder = styled.div`
  border-bottom: 1px solid #979797;
  font-weight: ${(props: { isBold?: boolean }) => (props.isBold ? 'bold' : '')};
`
export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
  height: '100%',
}))

export const StyledCards = styled.div`
  display: flex;
  justify-content: space-between;
  .C-Button {
    white-space: nowrap;
  }
`
export const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px dashed black;
  border-radius: 8px;
  padding: 24px 10px 24px 10px;
`
export const DrawerButtons = styled.div`
  display: flex;
  justify-content: end;
`
export const StyledReportProgress = styled.div`
  barcolor: 'primary';
`
