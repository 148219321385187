import { useState, useMemo } from 'react'
import { withFormik, Form, FormikProps } from 'formik'
import * as yup from 'yup'
import { useQuery } from '@tanstack/react-query'
import { Card } from '@enterprise-ui/canvas-ui-react'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import { Heading } from '@enterprise-ui/canvas-ui-react'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import { SellerTypeahead } from 'components/common/Typeahead'
import InputField from 'components/common/form/InputField'
import SelectField from 'components/common/form/SelectField'

import { ValidationSchema } from 'services/validation'
import {
  getIsoString,
  isAfterDate,
  formatDate,
  DATE_PICKER_FORMAT,
} from 'services/dateService'

import { SmsSeller } from 'types/Seller'
import { isSmsSeller } from 'types/Guards'
import {
  getEffectiveMarkupByCarrier,
  updatePartnerDiscount,
} from 'services/sellerShippingManagement'
import { Carrier } from 'types/Carrier'
import { Discount } from 'types/Markup'

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
}))

const StyledCarrierCard = styled(Card)({
  border: ' 1px solid var(--canvas-border-color--default-hover)',
})

const StyledEstimatedMarkup = styled('span')({
  color: 'var(--canvas-font-color--interactive)',
})

const rateRegex = /^(?:[1-9]\d?(?:\.\d)?|100(?:\.0)?)$/ // numbers between 1 and 100 inclusive, to the tenths place

const validationSchema: ValidationSchema = yup.object().shape({
  carrier: yup.string().label('Carrier').required(),
  partnerDiscount: yup
    .string()
    .required()
    .matches(rateRegex, 'Please enter a markup between 1% and 100%'),
  startDate: yup.string().label('Start Date').required(),
})

export interface FormValues {
  sellerId: string
  carrier: string
  startDate: string
  endDate: string
  partnerDiscount: string | number
  sellerName?: string
  seller?: SmsSeller
  id?: string
}

export interface ContainerProps {
  isOpen: boolean
  partnerDiscountDetails?: Discount
  memberOf?: string[]
  handleClose: (discount: Discount, sellerName: string) => void
  handleDelete: (discount: Discount) => () => void
}
export interface ComponentProps extends FormValues, ContainerProps {
  isValid: boolean
  handleSubmit: any
}

export type Props = ComponentProps & FormikProps<FormValues>

export const PartnerDiscountForm = ({
  partnerDiscountDetails,
  isOpen,
  handleSubmit,
  values,
  isSubmitting,
  isValid,
  dirty,
  setFieldValue,
  seller,
}: Props) => {
  const [selectedSeller, setSelectedSeller] = useState<SmsSeller | undefined>(
    seller,
  )

  const { data: effectiveMarkup } = useQuery(
    ['GET_EFFECTIVE_MARKUP', values.carrier],
    () => {
      if (values.carrier) {
        return getEffectiveMarkupByCarrier(
          values.carrier.toUpperCase() as Carrier,
        ).then((res) => {
          return res
        })
      }
      return null
    },
  )

  const handleSellerSelect = (value: SmsSeller | undefined | null) => {
    if (value && isSmsSeller(value)) {
      setSelectedSeller(value)
    }
    setFieldValue('sellerId', value?.id)
    setFieldValue('sellerName', value?.legal_business_name)
  }

  const onSeasonalEventDelete = () => {
    setFieldValue('seasonalAttributeValue', '')
  }

  const estimatedMarkeup = useMemo(() => {
    if (
      effectiveMarkup?.rate &&
      values?.partnerDiscount &&
      rateRegex.test(values?.partnerDiscount as string)
    ) {
      const carrierMarkup = effectiveMarkup?.rate * 100
      return carrierMarkup * (1 - Number(values.partnerDiscount) / 100)
    } else {
      return null
    }
  }, [effectiveMarkup?.rate, values?.partnerDiscount])

  const canSubmitForm = isValid && selectedSeller

  return (
    <DialogContainer
      data-testid="edit-source-dialog"
      isOpen={isOpen}
      title={
        partnerDiscountDetails
          ? `Edit Partner Discount`
          : 'Add Partner Discount'
      }
      isPending={isSubmitting}
      onSubmit={handleSubmit}
      isSubmitDisabled={!canSubmitForm || !dirty}
    >
      <Form>
        <Grid container spacing={2}>
          <StyledRoot>
            <Grid item xs={12}>
              <SellerTypeahead
                value={selectedSeller}
                onChange={handleSellerSelect}
                isDisabled={isSubmitting || !!partnerDiscountDetails}
                onClear={onSeasonalEventDelete}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="hc-fs-sm hc-mt-dense">
                Discounts set at the partner level will be applied to carrier
                markups on the carrier tab starting 12am CST of the selected
                effective date.
              </p>
            </Grid>
          </StyledRoot>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputField
                  required
                  type="date"
                  name="startDate"
                  label="Start Date"
                  min={new Date().toISOString().split('T')[0]}
                  max={values.endDate}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  type="date"
                  name="endDate"
                  label="End Date"
                  min={values.startDate}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <SelectField
                  required
                  name="carrier"
                  label="Select Carrier"
                  options={['FedEx', 'UPS']}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                {effectiveMarkup?.carrier && (
                  <StyledCarrierCard
                    elevation={0}
                    className="hc-bg-grey05 hc-pa-normal hc-fs-sm"
                  >
                    <div>{`${
                      values.carrier
                    } Carrier Markup is currently set to ${
                      effectiveMarkup?.rate * 100
                    }%.`}</div>
                  </StyledCarrierCard>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={4}>
                <InputField
                  required
                  name="partnerDiscount"
                  label="Partner Discount %"
                  disabled={!values.carrier || isSubmitting}
                />
              </Grid>
              {estimatedMarkeup && (
                <Grid item xs={6}>
                  <Heading size={6}>
                    Estimated Partner Markup:{' '}
                    <StyledEstimatedMarkup>
                      {estimatedMarkeup}%
                    </StyledEstimatedMarkup>
                  </Heading>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </DialogContainer>
  )
}

export const AddPartnerDiscount = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: (props) => {
    const { partnerDiscountDetails } = props

    let startDate = ''
    let endDate = ''
    let carrier = ''

    if (partnerDiscountDetails?.start_date) {
      startDate = formatDate(
        partnerDiscountDetails.start_date,
        DATE_PICKER_FORMAT,
      )
    }

    if (partnerDiscountDetails?.end_date) {
      endDate = formatDate(partnerDiscountDetails.end_date, DATE_PICKER_FORMAT)
    }

    if (partnerDiscountDetails?.carrier) {
      if (partnerDiscountDetails?.carrier === 'FEDEX') {
        carrier = 'FedEx'
      } else {
        carrier = partnerDiscountDetails.carrier
      }
    }

    return {
      sellerId: partnerDiscountDetails?.seller_id ?? '',
      partnerDiscount: partnerDiscountDetails?.rate
        ? partnerDiscountDetails.rate * 100
        : '',
      startDate,
      endDate,
      carrier,
      id: partnerDiscountDetails?.id ? partnerDiscountDetails.id : undefined,
    }
  },
  handleSubmit: async (values, { props, setFieldError, setSubmitting }) => {
    const {
      sellerId,
      carrier,
      partnerDiscount,
      startDate,
      endDate,
      sellerName,
      id,
    } = values
    const isoStartDate = getIsoString(startDate)
    const isoEndDate = endDate
      ? getIsoString(`${endDate}T23:59:59.000Z`) // Adding end time as T23:59:59.000Z to the end date
      : undefined

    if (isoStartDate && isoEndDate) {
      if (isAfterDate(new Date(isoStartDate), new Date(isoEndDate))) {
        setFieldError('startDate', 'Start date cannot be after end date')
        setSubmitting(false)
        return
      }
    }

    const newRate = parseFloat(partnerDiscount.toString()) / 100
    const selectedCarrier = carrier.toUpperCase() as Carrier

    const partnerDiscountUpdate = {
      seller_id: sellerId,
      carrier: selectedCarrier,
      rate: newRate,
      start_date: isoStartDate,
      end_date: isoEndDate,
      id: id ? id : '',
    } as Discount

    try {
      await updatePartnerDiscount(partnerDiscountUpdate)
      const displayName =
        (id ? props?.seller?.legal_business_name : sellerName) ?? ''
      props.handleClose(partnerDiscountUpdate, displayName)
    } catch (e) {
      setSubmitting(false)
    }
  },
  enableReinitialize: true,
  validationSchema,
})(PartnerDiscountForm)

export default AddPartnerDiscount
