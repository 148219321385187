import { Chip } from '@enterprise-ui/canvas-ui-react'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { TableHook } from 'components/common/EnhancedTable/useTable'
import TableSpacer from 'components/common/TableSpacer'
import {
  formatDateMDY,
  formatDateMDYT,
  formatPercentNotFixed,
  formatPercentString,
} from 'components/common/EnhancedTable/formatters'

import { Discount } from 'types/Markup'
import { Carrier } from 'types/Carrier'

import { calculatePartnerDiscountedMarkup } from 'services/sellerShippingManagement'

interface Props {
  table: TableHook
  data: Discount[]
  total: number
  isPending: boolean
}

const fieldList: EnhancedTableFieldType<Discount>[] = [
  {
    key: 'carrier',
    heading: 'Carrier',
    hasSort: true,
    formatCell: (discount) =>
      discount.carrier === Carrier.FEDEX ? 'FedEx' : discount.carrier,
  },

  {
    key: 'start_date',
    heading: 'Effective Start Date',
    hasSort: true,
    formatCell: formatDateMDY('start_date'),
  },
  {
    key: 'end_date',
    heading: 'Effective End Date',
    hasSort: true,
    formatCell: formatDateMDY('end_date'),
  },
  {
    key: 'rate',
    heading: 'Discount %',
    hasSort: true,
    formatCell: formatPercentNotFixed('rate'),
  },
  {
    key: '',
    heading: 'Discount Markup %',
    formatCellAsync: async (discount) => {
      const discountedMarkup = await calculatePartnerDiscountedMarkup({
        carrier: discount.carrier,
        sellerId: discount.seller_id,
        effectiveDate: discount.start_date,
      })

      return formatPercentString(discountedMarkup)
    },
  },
  {
    key: 'last_modified_by',
    heading: 'Last Modified By',
    hasSort: true,
  },
  {
    key: 'last_modified',
    heading: 'Last Modified',
    hasSort: true,
    formatCell: formatDateMDYT('last_modified'),
  },
  {
    key: 'status',
    heading: 'Status',
    formatCell: ({ status }) => {
      if (!status) {
        return null
      }

      if (status === 'ACTIVE') {
        return <Chip color="success">Active</Chip>
      } else {
        return <Chip className="hc-bg-grey02">Inactive</Chip>
      }
    },
  },
]

export const PartnerDiscountHistoryTable = ({
  table,
  data,
  total,
  isPending,
}: Props) => {
  return (
    <TableSpacer>
      <EnhancedTable
        total={total}
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table.actions.changePerPage}
        onRequestSort={table.actions.sort}
        orderBy={table.state.orderBy}
        order={table.state.direction}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        fieldList={fieldList}
        paginationDisabled={false}
        data={data}
        isLoading={isPending}
        isUpdatedTable
      />
    </TableSpacer>
  )
}

export default PartnerDiscountHistoryTable
