import axios from 'axios'
import flow from 'lodash/fp/flow'
import last from 'lodash/fp/last'
import split from 'lodash/fp/split'

import apiConfig from 'config/apiConfig'
import { DOWNLOAD_COUNT_LIMIT } from '../constants/sizeLimits'

import {
  isoStringStartOfDay,
  isoStringEndOfDay,
  formatDate,
  DATE_PICKER_FORMAT,
  DATE_DISPLAY_FORMAT_TIME,
} from './dateService'
import { saveFile } from 'services/files'
import { trackCustomEvent } from './fireflyInsights'
import { getPageable, PagingParams } from './pageableHelper'

import { FireflyEvent } from 'types/FireflyInsights'
import { Report, ReportParameters, ReportType } from 'types/Report'
import { CollectionResponse } from 'types/Response'

export interface GenerateReportRequest {
  type: string
  sellerId?: string
  startDate?: Date | string
  endDate?: Date | string
  parameters?: ReportParameters
  reportName?: string
  reportInput?: File
  format?: 'CSV' | 'EXCEL'
  isInternal?: boolean
  error_code?: string
}

export async function submitReport(
  request: GenerateReportRequest,
): Promise<Report> {
  let url
  if (!request.isInternal && request.sellerId) {
    url = `${apiConfig.sms}/sellers/${request.sellerId}/report_requests`
  } else {
    url = `${apiConfig.sms}/report_requests`
  }

  const postData = new FormData()

  const reportType = request.parameters?.metrics_type ?? request.type
  const params =
    reportType === ReportType.SALES_TAX
      ? {
          // params for reports that use Greenfield data
          type: request.type,
          report_name: request.reportName,
          format: 'CSV',
          seller_id: request.sellerId,
          parameters: {
            start_day: request.startDate
              ? formatDate(request.startDate, DATE_PICKER_FORMAT)
              : undefined,
            end_day: request.endDate
              ? formatDate(request.endDate, DATE_PICKER_FORMAT)
              : undefined,
          },
        }
      : {
          type: request.type,
          start_date: request.startDate
            ? isoStringStartOfDay(request.startDate)
            : undefined,
          end_date: request.endDate
            ? isoStringEndOfDay(request.endDate)
            : undefined,
          parameters: {
            ...request.parameters,
            include_metadata: request.format === 'EXCEL' || !request.format,
            ...(request.error_code && { error_code: request?.error_code }),
          },
          report_name: request.reportName,
          format: request.format || 'EXCEL',
          seller_id: (request.isInternal && request.sellerId) ?? undefined,
        }

  const json = JSON.stringify(params)

  postData.append(
    'report_request',
    new Blob([json], { type: 'application/json' }),
  )

  if (request.reportInput) {
    postData.append(
      'report_input',
      new Blob([request.reportInput]),
      request.reportInput.name,
    )
  }

  const { data } = await axios.post(url, postData)

  trackCustomEvent(
    FireflyEvent.REPORT_GENERATE,
    'report_type',
    reportType,
    JSON.stringify(params.parameters),
  )

  return data
}

type GetReportsParams = {
  sellerId: string | undefined
  createdBy?: string
}
export async function getReports(
  pageable: PagingParams,
  searchParams: GetReportsParams,
  type?: string,
): Promise<CollectionResponse<Report>> {
  const pageableParams = getPageable(pageable)
  const config = {
    params: {
      ...pageableParams,
      created_by: searchParams.createdBy,
      format: ['EXCEL', 'CSV'],
      ...(type && {
        type: type,
      }),
    },
  }

  const response = searchParams.sellerId
    ? await axios.get(
        `${apiConfig.sms}/sellers/${searchParams.sellerId}/report_requests`,
        config,
      )
    : await axios.get(`${apiConfig.sms}/report_requests`, config)

  const { data, headers = {} } = response

  const total = headers['x-total-count']
    ? parseInt(headers['x-total-count'], 10)
    : 0

  return {
    total,
    data,
  }
}

type GetBulkLegalizationReportsParams = {
  sellerId: string | undefined
  type: string
  createdBy?: string
}
type GetBulkItemUnlistReportsParams = {
  type: string
  createdBy?: string
}
export async function getBulkLegalizationReports(
  pageable: PagingParams,
  searchParams: GetBulkLegalizationReportsParams,
): Promise<CollectionResponse<Report>> {
  const pageableParams = getPageable(pageable)
  const config = {
    params: {
      ...pageableParams,
      created_by: searchParams.createdBy,
      format: ['EXCEL', 'CSV'],
      type: ReportType.BULK_LEGALIZATION,
      seller_id: searchParams.sellerId,
    },
  }

  const response = await axios.get(`${apiConfig.sms}/report_requests`, config)
  const { data, headers = {} } = response

  const total = headers['x-total-count']
    ? parseInt(headers['x-total-count'], 10)
    : 0

  return {
    total,
    data,
  }
}

export async function getBulkItemUnlistReports(
  pageable: PagingParams,
  searchParams: GetBulkItemUnlistReportsParams,
): Promise<CollectionResponse<Report>> {
  const pageableParams = getPageable(pageable)
  const config = {
    params: {
      ...pageableParams,
      created_by: searchParams.createdBy,
      format: ['EXCEL', 'CSV'],
      type: ReportType.BULK_ITEM_UNLIST,
    },
  }

  const response = await axios.get(`${apiConfig.sms}/report_requests`, config)
  const { data, headers = {} } = response

  const total = headers['x-total-count']
    ? parseInt(headers['x-total-count'], 10)
    : 0

  return {
    total,
    data,
  }
}

export async function getTwoDayUpload(
  pageable: PagingParams,
  searchParams: GetBulkLegalizationReportsParams,
): Promise<Report> {
  const pageableParams = getPageable(pageable)
  const config = {
    params: {
      ...pageableParams,
      created_by: searchParams.createdBy,
      format: ['EXCEL', 'CSV'],
      type: searchParams.type,
    },
  }
  const url = `${apiConfig.sms}/sellers/${searchParams.sellerId}/report_requests`

  const { data } = await axios.get(url, config)

  return data
}

export async function pollReport(
  reportId: string,
  sellerId?: string,
): Promise<Report> {
  let url = `${apiConfig.sms}/report_requests/${reportId}`

  if (sellerId) {
    url = `${apiConfig.sms}/sellers/${sellerId}/report_requests/${reportId}`
  }

  const { data } = await axios.get(url)

  return data
}

export function cancelReport({
  sellerId,
  reportId,
}: {
  sellerId?: string
  reportId: string
}) {
  if (sellerId) {
    return axios.delete(
      `${apiConfig.sms}/sellers/${sellerId}/report_requests/${reportId}`,
    )
  } else {
    return axios.delete(`${apiConfig.sms}/report_requests/${reportId}`)
  }
}

export async function downloadReport(downloadUrl: string, reportType?: string) {
  const { data } = await axios.get(downloadUrl, {
    headers: {
      Accept: 'application/octet-stream',
    },
    responseType: 'blob',
    ...(reportType === ReportType.BULK_ITEM_UNLIST && {
      params: { report_type: ReportType.BULK_ITEM_UNLIST },
    }),
  })

  return data
}

export const getFilename = ({
  report,
  title,
  hasDateRange,
  sellerName,
}: {
  report: Report
  title: string
  hasDateRange: boolean
  sellerName?: string
}) => {
  const { download_url } = report
  const extension = flow(split('.'), last)(download_url!)

  const sellerOrName = report.report_name || sellerName
  const nameString =
    sellerOrName && sellerOrName !== title ? ` ${sellerOrName} ` : ' '
  const dateString = hasDateRange
    ? `${formatDate(report.start_date, DATE_DISPLAY_FORMAT_TIME)}-${formatDate(
        report.end_date,
        DATE_DISPLAY_FORMAT_TIME,
      )}`
    : `${formatDate(report.created, DATE_DISPLAY_FORMAT_TIME)}`
  return `${title}${nameString}${dateString}.${extension}`
}

export async function saveReportAsFile({
  report,
  title,
  hasDateRange,
  sellerName,
}: {
  report: Report
  title: string
  hasDateRange: boolean
  sellerName?: string
}) {
  if (report) {
    trackCustomEvent(FireflyEvent.REPORT_DOWNLOAD, 'report_type', report.type)

    const data = await downloadReport(report.download_url!, report.type)
    saveFile({
      data,
      name: getFilename({ report, title, hasDateRange, sellerName }),
      type: 'application/octet-stream;charset=utf-8',
    })
  }
}

export const checkDownloadLimit = (count: number | undefined): boolean => {
  return typeof count === 'number' && count > DOWNLOAD_COUNT_LIMIT
}
