import { PhoneNumber } from './PhoneNumber'
import { ItemData } from './Item'

export interface Order {
  id: string
  currency: string
  level_of_service: string
  order_date: string
  order_lines: OrderLine[]
  order_number: string
  order_status: OrderStatus
  requested_delivery_date: string
  requested_shipment_date: string
  seller_id: string
  sellerDisplayName: string
  ship_advice_number: string
  shipping_method: string
  vmm_vendor_id: string
}

export interface OrderLine extends OrderItemData {
  order_line_number: string
  order_line_statuses: OrderLineStatus[]
  cancellation_reason?: string
  routing: string
  tcin: string
  total_shipping_price: number
  total_shipping_tax_price: number
  total_handling_price: number
  total_handling_tax_price: number
  total_tax_price: number
  total_price: number
  total_item_discount: number
  total_shipping_discount: number
  total_gift_option_price: number
  total_gift_option_tax_price: number
  unit_price: number
}

export interface OrderLineStatus {
  status: string
  quantity: number
}

export interface ReturnTrackingData {
  inventory_removal_reason?: string
  tracking_number?: string
  scac?: string
  license_plate?: number | string
  ship_date?: string
  store_physical_disposition?: string
  crc_received?: string
  chargeback_processed?: boolean
  bill_of_lading?: string
  receiving_location_id?: number
  shipment_id?: string
}

export interface InboundTrackingData {
  inbound_location_id: number
  inbound_scac: string
  inbound_ship_date: string
  inbound_tracking_number: string
}

export interface Return {
  created?: string
  created_by?: string
  last_modified?: string
  last_modified_by?: string
  external_id: string
  id: string
  order_id: string
  order_number: string
  quantity: number
  return_date: string
  return_order_number: string
  seller_id: string
  tcin: string
  return_reason: string
  is_online: boolean
  customer_can_keep: boolean
  physical_disposition: string
  financial_disposition: string
  location_id?: number
  reference_id?: string
  tracking_data?: ReturnTrackingData[]
  inbound_tracking_data?: InboundTrackingData
  registry_item?: boolean
}

export interface ReturnWithDetail extends Return {
  productTitle: string
  image: string
  product_id: string
}

export interface ReturnOrderProduct extends Return {
  productPrimaryImage?: string
  productTitle?: string
}

export interface OrderCounts {
  unShipped: number
  partiallyShipped: number
  total: number
  returned: number
}

export interface OrderAddresses {
  id: string
  billing_address: OrderAddress
  shipping_address: OrderAddress
}

export interface OrderAddress {
  city: string
  email: string
  state: string
  address1: string
  address2: string
  last_name: string
  first_name: string
  name_suffix: string
  postal_code: string
  company_name: string
  country_code: string
  phone_numbers: PhoneNumber[]
}

export interface OrderSearchParams {
  seller_id?: string
  query?: string // searches OrderNumber, ShipAdviseNumber and Id
  order_status?: string | string[]
  order_number?: OrderStatus
  ship_advice_number?: string
  tcin?: string
  order_date?: string // format '2017-03-03T00:00:00.123Z/2018-03-03T00:00:00.123Z' use dateService.formatDateRange
  requested_shipment_date?: string
  order_id?: string
}

export interface ReturnSearchParams {
  seller_id?: string
  order_id?: string
  tcin?: string
  crc_received_date?: string
  return_date?: string
  ship_date?: string
  license_plate?: string
  tracking_number?: string
  bill_of_lading?: string
  query?: string // searches order_id, order_number return_order_number and tcin
  return_order_number?: string
  shipment_id?: string
}

export enum OrderStatus {
  PENDING = 'PENDING',
  RELEASED_FOR_SHIPMENT = 'RELEASED_FOR_SHIPMENT',
  ACKNOWLEDGED_BY_SELLER = 'ACKNOWLEDGED_BY_SELLER',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  SHIPPED = 'SHIPPED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
}

export interface Fulfillment extends OrderItemData {
  id: string
  last_modified: string
  level_of_service: string
  order_id: string
  order_line_number: string
  seller_id: string
  shipped_date: string
  shipping_method: string
  tracking_number: string
}

export interface OrderItemData extends ItemData {
  quantity: number
}

export interface Shipment {
  last_modified: string
  order_id: string
  seller_id: string
  shipped_date: string
  shipping_method: string
  tracking_number: string
  fulfillments: Fulfillment[]
}

export interface OrderHistory {
  id: string
  order_id: string
  event_timestamp: string
  status: OrderStatus
}

export interface sellerOrdersCount {
  seller_id: string
  name?: string
  count: number
}

export interface ReturnsResearch {
  id?: string
  message_type?: string
  inventory_removal_id?: number
  license_plate?: string
  receiving_location_id?: number
  physical_disposition?: string
  tcin?: string
  quantity?: number
  vendor_id?: number
  return_policy?: string
  store_reference_field?: string
  shipment_size?: number
  create_timestamp?: string
  event_timestamp?: string
  pro_number?: string
  shipment_id?: string
}
