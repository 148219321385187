import apiConfig from 'config/apiConfig'
import { ReportType } from 'types/Report'

export const TWO_DAY_STATE = {
  name: 'TWO_DAY_STATE',
  title: 'Configure States & Distribution Centers',
  subtitle:
    'Configure your Distribution Center (Node) & States that can ship within 2 Day TNT',
  dropText:
    'Upload new configuration State-Node Mapping Data.\nAccepted format: .xlsx',
  templateLink: `${apiConfig.sms}/templates/two_day_shipping_dc_state_template.xlsx`,
  templateName: 'two_day_shipping_dc_state_template.xlsx',
  type: 'DOWNLOAD_TWO_DAY_SHIPPING_STATES',
  reportType: ReportType.BULK_UPDATE_TWO_DAY_SHIPPING_STATES,
}

export const TWO_DAY_ITEM = {
  name: 'TWO_DAY_ITEM',
  title: 'Configure Items',
  subtitle:
    'Provide the list of items that can be shipped within 2 days of transit time',
  dropText:
    'Upload new configuration for items.\nAccepted format: .xlsx, Max rows 5000',
  templateLink: `${apiConfig.sms}/templates/two_day_shipping_products_template.xlsx`,
  templateName: 'two_day_shipping_products_template.xlsx',
  type: 'DOWNLOAD_TWO_DAY_SHIPPING_PRODUCTS',
  reportType: ReportType.BULK_UPDATE_TWO_DAY_SHIPPING_PRODUCTS,
}
